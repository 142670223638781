.download{
    &-card{
        .card{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-left: 0;
            margin-right: 0;

            @include setMinRwd{
                margin-left: 0.85em;
                margin-right: 0.85em;
            }
        }

        .card-img-top{
            border: #d9d9d9 3px solid;
            height: 0;
            padding-bottom: 100%;
            img{
                @extend %imgCenter
            }

            @include setMinRwd{
                padding-bottom: 125%;
            }
        }

        .btn-submain{
            background-color: #0091d4;
            border-color: #0091d4;
        }

        &-new{
            .card{
                background-color: transparent;
            }

            .card-img-top{
                border-color: #a2cbea;
                border-width: 10px;
                @include setMinRwd{
                    width: 430px;
                    padding-bottom: 65%;
                }
            }

            .card-body{
                @include setMinRwd{
                    margin-left: get-vw(120px);
                    margin-top: get-vw(80px);
                }
            }
            
            .card-head{
                @include setMinRwd{
                    padding-bottom: get-vw(100px);
                }
            }

            .btn{
                @include setMinRwd{
                    padding-left: get-vw(60px);
                    padding-right: get-vw(60px);
                }
            }

            .card-btn-wrap{
                @include setMaxRwd{
                    display: flex;
                    .btn{
                        
                    }
                }
            }
        }
    }
}