// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        @include setBackSet(top,cover);
        padding-top: 2em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            background-size: auto;
            padding: 1.5em 0.5em;
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                display: block;
                width: 100%;
                // flex-direction: column;
                // justify-content: center;
            }
        }

        &Swiper{
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                /* 通过改变animation-timing-function 制作弹性切换效果 */
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }

            // 共用
            .swiper-button-wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                top: -2em;
                .swiper-button-prev,
                .swiper-button-next{
                    @include setSize(47px,47px);
                    overflow: hidden;
                    
                    &::after {
                        display: none;
                    }

                    svg{
                        path{
                            fill: $colorMain;
                        }
                    }

                    &.swiper-button-disabled {
                        svg{
                            path{
                                fill: $colorSecondary;
                            }
                        }
                    }

                    @include setMinRwd{
                        position: static;
                    }
                }
            }
        }

        // 標題用
        &Head{
            
        }

        // 內文用
        &Article{
            @include setBackSet(top,cover);
            line-height: 1.5;

            .btn{
                border-color: #d9d9d9 !important;
                padding: 5px 20px;
                @include setMaxRwd{
                    &-wrap{
                        margin-top: 0;
                        margin-bottom: 0.5em;
                        padding-right: 0.5em;
                    }
                }
            }

            // 卡片
            .card{
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 0;
                background-color: transparent;
                p{
                    margin-bottom: 0;
                }

                &-head,
                &-title{
                    --min-size: 18;
                    --max-size: 20;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    @extend .fw-600;
                    line-height: 1.1;
                }

                &-title{
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                &-text{
                    color: #3c3c3c;
                    font-weight: initial;
                }

                &-img-top{
                    img{
                        display: inline-block;
                        width: auto;
                    }
                }
            }
        }

        &NewUpload{
            background-image: url('../images/index-pageNewUpload-bg.jpg');

            &-grid{
                padding-top: 2em;
                margin-left: -7.5px;
                margin-right: -7.5px;

                // 卡片
                .card{
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-bottom: 15px;
                    &-img-top{
                        height: 0;
                        img{
                            @extend %imgCenter;
                            width: 100%;
                        }
                    }

                    &-body{
                        display: flex;
                        align-items: stretch;
                        padding: 0;
                    }
                    
                    &-head{
                        background-color: #000;
                        color: #fff;
                        flex-basis: 140px;
                        padding: 2.25em 1.5em 1em;
                    }
                    
                    &-caption{
                        flex: 1;
                        background-color: rgba(255,255,255,0.75);
                        padding-top: 1.5em;
                        padding-bottom: 0.875em;
                    }

                    &-text{
                        --min-size: 18;
                        --max-size: 20;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    }

                    &-title{
                        font-size: 16px;
                        font-weight: 100 !important;
                    }

                    @include setMinRwd{
                        &-body{
                            position: absolute;
                            bottom: 0;
                            min-width: 28.5em;
                        }
                    }
                }
                
                .pageGrid{
                    // 第一排
                    &:first-child{
                        .card{
                            &:first-child{
                                .card-head{
                                    background-color: #53b7f1;
                                }

                                & ~ .card{
                                    .card-head{
                                        background-color: #a5d047;
                                    }
                                }
                            }

                            .card-img-top{
                                padding-bottom: 61.5%;
                            }
                        }
                    }

                    // 第二排
                    &:last-child{
                        .card{
                            .card-img-top{
                                padding-bottom: 100%;
                            }

                            &:nth-child(1){
                                .card-head{
                                    background-color: #e88a74;
                                }
                            }

                            &:nth-child(2){
                                .card-head{
                                    background-color: #facd89;
                                }
                            }
                            
                            &:nth-child(3){
                                .card-head{
                                    background-color: #8f82bc;
                                }
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                padding-top: 4em;
                padding-bottom: 4em;
                .btn-wrap{
                    margin-top: -5.5%;
                }
            }
        }

        &Recommend{
            background-image: url('../images/index-pageRecommend-bg.jpg');
            .index-pageWrapper{
                display: block;
            }

            .heading-title{
                &::before {
                    mix-blend-mode: color-burn;
                }
            }

            @include setMinRwd{
                padding-top: get-vw(70px);
                padding-bottom: get-vw(210px);
                .btn-wrap{
                    margin-top: -5.5%;
                    margin-bottom: 1.5%;
                }
            }

            @include setMaxRwd{
                padding-left: 0;
                padding-right: 0;
            }

            &-swiper{
                max-width: 100%;
                
                .card{
                    transform-origin: center;
                    padding: 1.5em;

                    &,
                    &-img-top{
                        @include radius(1.25em);
                        overflow: hidden;
                    }

                    &-body,
                    &-caption{
                        width: 100%;
                    }

                    &-body{
                        padding-left: 0;
                        padding-top: 0.75em;
                        padding-bottom: 0;
                        padding-right: 0;
                    }

                    &-caption{
                        padding-bottom: 0;
                    }

                    &::before{
                        content: '';
                        display: block;
                        @include setSize(100%,100%);
                        background-color: #fff;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 0;
                    }
                }

                .changing,
                .changed {  
                    user-select: none;
                }

                .changed {  
                    transition:none;
                }

                .changing {
                    transition:transform 0.3s;
                    pointer-events:none;
                }

                .changing.swiper-slide-active,
                .changed.swiper-slide-active {
                    transform:translateX(0);
                }

                @include setMinRwd{
                    padding-top: 4em;
                    .card{
                        transition:transform 0.3s;
                        &-body{
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }

                    .card-linkNormal{
                        transition: opacity 0.25s ease-in-out;
                    }

                    .swiper{
                        overflow: visible;
                    }

                    .changing,
                    .changed {  
                        transform:translateX(-100px);
                    }

                    .changing.swiper-slide-active,
                    .changed.swiper-slide-active {
                        .card{
                            transform: scale(1.5);
                        
                            &-body{
                                position: relative;
                                opacity: 1;
                                visibility: visible;
                            }
                            
                            &-text {
                                font-size: 82%;
                            }
                        
                            &-title{
                                font-size: 105%;
                            }
                        }
                    }

                    .swiper-slide-active ~ .changing,
                    .swiper-slide-active ~ .changed {
                        transform:translateX(75px);
                    }
                }

                @include setStyleMinRwd(1500px){
                    .card{
                        padding: 2.5em;
                    }

                    .changing.swiper-slide-active,
                    .changed.swiper-slide-active {
                        .card{
                            transform: scale(1.2);
                        }
                    }
                }

                @include setMaxRwd{
                    padding-top: 1em;
                    .card{
                        padding: 0.5em;
                        opacity: 0.5;
                        &-caption{
                            padding-left: 0.5em;
                            padding-right: 0.5em;
                        }
                    }

                    .changing.swiper-slide-active,
                    .changed.swiper-slide-active {
                        .card{
                            opacity: 1;
                        }
                    }
                }

                .swiper-button{
                    &-wrapper{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        z-index: 1;
                        display: flex;
                        max-width: 25%;
                        width: 100%;
                        justify-content: space-between;
                        transform: translate(-50%,-50%);
                        @include setWidthRwd(1100px,1500px){
                            max-width:30%;
                        }
                        @include setMaxRwd{
                            max-width: calc(50% + 45px);
                        }
                    }
                    
                    &-next,
                    &-prev{
                        position: static;
                        @include setSize(55px,55px);
                        &::after{
                            display: none;
                        }

                        @include setMaxRwd{
                            &, svg, svg > image{
                                @include setSize(45px,45px);
                            }
                        }
                    }
                }
            }
        }

        &Customer{
            background-image: url('../images/index-pageCustomer-bg.jpg');
            background-size: 100%;
            background-color: #e5e5e5;

            .heading-title{
                text-align: center;
                justify-content: center;
                margin-bottom: 1em;

                &-txt{
                    color: #fff;
                }

                &::before {
                    position: absolute;
                    top: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -0.75em;
                    @include setSizeMax(50px,6px);
                    background-image: url("../images/heading-title-icon02.jpg");
                }
            }

            &-listNav{
                margin-top: 4.25em;
                margin-bottom: 3.25em;
                .list-group-item{
                    border-radius: 0 !important;
                    background-color: transparent;
                    border:none;
                    color: #fff;
                    padding: 0;
                    text-align: center;
                    margin-right: -1px;

                    a{
                        display: block;
                        padding: 0.6em 0.35em;
                        border: #fff 1px solid;
                        --min-size: 15;
                        --max-size: 18;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    }

                    &:hover,
                    &.active{
                        background-color: black;
                    }

                    &:first-child{
                        margin-right: -1px;
                    }

                    &:last-child{
                        margin-left: 0px;
                    }

                    @include setMinRwd{
                        a{
                            padding: 0.65em 2.75em;
                        }
                    }

                    @include setMaxRwd{
                        flex: 1;
                    }
                }

                @include setMaxRwd{
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
            }

            &-infoSwiper{
                .swiper-pagination{
                    .swiper-pagination-bullet{
                        @include setSize(10px,10px);
                        opacity: 1;
                        background-color: $colorSecondary;
                        &-active{
                            background-color: black;
                        }
                    }

                    @include setMinRwd{
                        width: 50% !important;
                        left: 50% !important;
                        bottom: 12.5% !important;
                    }
                }

                @include setMaxRwd{
                    padding-bottom: 3em;
                }
            }

            &-infoWrap{
                .card{
                    &-img-top{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #fff;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }

                    &-body{
                        background-color: #f3f2f2;
                        padding: get-vw(80px) get-vw(90px);
                    }

                    &-headWrap{

                    }

                    &-remark{
                        position: absolute;
                        right: 0;
                        top: -3px;
                    }

                    &-title{
                        font-size: 16px;
                        color: #3c3c3c;
                        margin-top: 1em;
                        &-num{
                            font-size: 18px;
                            @extend .fw-600;
                            padding-right: 10px;
                        }
                    }

                    &-caption{
                        border-top: $colorSecondary 1px solid;
                        border-bottom: $colorSecondary 1px solid;
                        line-height: 1.5;
                        padding: 1.5em 0;
                        margin-top: 2em;
                    }

                    @include setMinRwd{
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        &-img-top,
                        &-body{
                            width: 50%;
                        }
                    }
                }

                .btn{
                    border-color: transparent !important;
                    &-wrap{
                        margin-top: 1.5em;
                    }
                }
            }

            @include setMinRwd{
                padding-top: get-vw(120px);
                padding-bottom: get-vw(80px);
            }

            @include setMaxRwd{
                background-size: auto;
            }
        }
    }
}

// 
.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}



/* swiper index banner */
.swiper-bannerIn{
	width: 100%;
	height: 100%;
    overflow: hidden;

    .swiper-container {
        width: 100%;
        height: 100%;
    }
    
    .swiper-image {
        height: 100%;
    }

    .swiper-image-inner {
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-blend-mode: overlay;
    }

    .swiper-slide{
        transition: all 1s linear;
        transition-delay: 0.5s;
        opacity: 0;
        overflow: hidden;
    }

    .swiper-slide.swiper-slide-next {
        opacity: 0;
    }

    .swiper-slide.swiper-slide-active{
        opacity: 1;
        width: 100% !important;
    }

    .swiper-slide.swiper-slide-active .swiper-image{
        overflow: hidden;
    }
    
    .swiper-image.swiper-image-active{
        img{
            width: 100%;
            height: 730px;
            object-fit: cover;

            @include setWidthRwd(1100px,1650px){
                height: 40em;
            }
        }
    }

    .swiper-image-inner.swiper-image-active {
        transition: all 1s linear;
        transition-delay: 1s;
        background-blend-mode: multiply;
    }

    /* Arrows */
    .swiper-button-prev,
    .swiper-button-next {
        width: 39px;
        transition: var(--fancy-slider-transition-button);
        outline: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;

        &::after{
            display: none;
        }

        @include setMaxRwd{
            width: 40px;
            opacity: 0.75;
            svg {
                image{
                    height: 45px;
                }
            }
        }
    
        svg {
            display: block;
            transition: var(--fancy-slider-transition-button);
        }
    }

    .swiper-button-prev{
        left: 0;
        @include setMaxRwd{
            left: -2%;
        }
    }

    .swiper-button-next{
        right: 0;
        @include setMaxRwd{
            right: -2%;
        }
    }

    // 
    .swiper-pagination{
        width: 100%;
        position: absolute;
        text-align: center;
        transition: 300ms;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        &-bullet {
            width: 8px;
            height: 8px;
            background: #000;
            opacity: 1;
            &-active {
                opacity: 1;
                background: transparent;
                transform: scale(2);
                border: #000 2px solid;
            }
        }

        @include setMinRwd{
            flex-direction: column;
            display: flex;
            min-height: 8em;
            bottom: auto !important;
            top: 50%;
            left: 2em !important;
            justify-content: space-around;
        }
    }

    // 
    .swiper-sideslogan{
        position: absolute;
        right: 4em;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        @include setMaxRwd{
            display: none;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &-text{
            --min-size: 16;
            --max-size: 18;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            font-family: $Raleway;
            color: #fff;
            position: relative;
            display: flex;
            align-items: center;
            min-width: 22em;
            width: 100%;
            transform: rotate(90deg) translateX(50%);
            transform-origin: right center;
            &::before{
                content: "";
                display: block;
                flex: 1;
                width: 100%;
                height: 1px;
                background-color: #fff;
                margin-right: 2em;
                overflow: hidden;
            }
        }
    }
      
}


.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            background: linear-gradient(to bottom,  #ffffff 0%,#f7f7f7 60%,#ffffff 60%,#f7f7f7 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }
        }

        &-main{
            @include mobile{
                img{
                    height: 100px;
                    object-fit: cover;
                }

                &::before{
                    top: -50%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                text-align: center;
                img{
                    display: inline-block;
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.2;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 首頁產品分類 swiper
        &-pro{
            width: 100%;
            padding-top: get-vw(15px);
            padding-bottom: get-vw(40px);
            .card{
                &-body{
                    @include setSize(100%,100%);

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include setMinRwd{
                        position: absolute;
                        padding: 3em;
                        color: #fff;
                        background-color: rgba(49, 49, 49,0.85);
                    }
                }

                &-title{
                    @include fontSize(42px);
                    font-family: var(--fancy-slider-title-font-family);
                    border-bottom: $colorBase 1px solid;
                    text-align: center;
                    padding-bottom: 0.3em;
                    margin-bottom: 0.35em;

                    @include setMinRwd{
                        border-bottom-color: rgba(255,255,255,0.65); 
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

                @include setMinRwd{
                    &-linkNormal{
                        & ~ .card-body{
                            opacity: 0;
                            transition: var(--card-transition-duration);
                            .card-title,
                            .card-des{
                                transition-duration: var(--card-transition-duration);
                                transition-delay: var(--card-transition-delay);
                            }
    
                            .card-title{
                                transform: translateY(-1000%);
                            }
    
                            .card-des{
                                transform: translateY(1000%);
                            }
                        }
    
                        &:hover{
                            & ~ .card-body{
                                opacity: 1;
                                .card-title,
                                .card-des{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }

                @include pad{
                    flex-direction: row;
                    align-items: flex-start;
                    background-color: transparent;
                    
                    &-img-top{
                        @include radius(20px);
                        overflow: hidden;
                        flex-basis: 150px;
                        margin-top: 46px;
                    }

                    &-body{
                        padding-left: 1.25em;
                        padding-right: 0.25em;
                        flex: 0 0 calc(100% - 150px);
                    }

                    &-title{
                        @include fontSize(25px);
                    }
                }
            }

            .swiper{
                &-slide{
                    .card{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    @include setMinRwd{
                        &-active,
                        &-next{
                            .card{
                                border-bottom-right-radius: 0 !important
                            }
                        }

                        &-active{
                            .card{
                                border-top-left-radius: 40%;
                            }
                        }

                        &-next{
                            .card{
                                @include radius(20px);
                            }
                        }

                        .card{
                            border-bottom-right-radius: 40%;
                            overflow: hidden;
                        }
                    }
                }

                &-act{
                    &-container{
                        @extend %setFlex;
                        align-items: flex-end;
                        justify-content: end;
                        margin-bottom: 2.75em;
                        padding-right: 1em;

                        .swiper-pagination {
                            position: relative;
                            width: auto;
                            top: 0px;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(100%,1px);
                                background-color: #cbcbcb;
                                position: absolute;
                                top: 25px;
                                z-index: -1;
                            }
                        }
                    }

                    &-button{
                        width: calc(3.5em + 10px);
                        padding-left: 10px;
                        @extend %setFlex-center;
                        justify-content: space-between;
                        .swiper-button-next,
                        .swiper-button-prev {
                            position: static;
                            color: $colorBase;
                            height: auto;
                            margin-top: 0;
                            &::after{
                                font-size: 14px;
                                @extend .fw-900
                            }
                        }
                    }
                }

                &-pagination{
                    &-fraction{
                        span{
                            color: #b9b9b9;
                            font-size: 15px;
                            font-family: $OpenSans-300;
                            padding: 0 2px;
                        }

                        .swiper-pagination-empty{
                            width: 30px;
                        }

                        .swiper-pagination-current{
                            position: relative;
                            color: $colorBase;
                            font-weight: 600;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(30px,3px);
                                background-color: $colorMain;
                                position: absolute;
                                bottom: -2px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                display: flex;
                flex-direction: column;
                .swiper-act-container{
                    order: 1;
                    margin-top: 1.1em;
                }
            }
        }
    }

    // 內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;

        &-list{
            &-frAuto{
                grid-template-columns: repeat(2, auto) 1fr
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }

            &-3to1{
                grid-template-columns: 1fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}
