// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}
%btnItemHover{
    background-color: $colorItem !important;
    border-color: $colorItem !important;
    color: #fff !important;
}

// 按鈕樣式
.btn{
    @include fontSize(18px);
    font-weight: 100;
    border-radius: 0 !important;
    padding: 0.35em 1.5em 0.5em;

    &:hover{
        @extend %btnMainHover;
    }

    @include setMaxRwd{
        @include fontSize(16px);
        padding-left: 1em;
        padding-right: 1em;
    }

    .icon {
        vertical-align: top;
        margin-top: 0px;
        margin-right: 3px;
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }
        @include pad1080 {
            margin-top: 1rem;
            margin-bottom: 2rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    // 顏色
    &-skin{
        background-color: #dec9bc;
    }

    &-main{
        background-color: $colorMain;
        color: #fff;
    }

    &-white{
        background-color: #fff;
    }

    &-outline{
        &-dark{
            border:1px solid #212529 !important
        }
    }

    &-corp{
        position: relative;
        z-index: 0;
        border-radius: 4em !important;
        line-height: 1.75em !important;
        &::before{
            content: "";
            display: block;
            @include setSize(calc(100% + 12px),calc(100% + 12px));
            border: #000 1px solid;
            @include radius(4em);
            position: absolute;
            top: -6px;
            left: -6px;
            z-index: 1;
        }
    }
}

.js-toggle-btn{
    .btn{
        @include fontSize(16px);
        @include radius(20px);
        border: #eee 1px solid;
        i{
            color: currentColor;
        }

        .icon{
            margin-top: 0;
        }
    }
}

.js-open{
    display: block !important;
    margin-top: 5px;
}