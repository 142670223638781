.flip-wrapper {
    margin: 2em auto;
}

.flip-book {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    display: none;
    background-size: cover;

    .page {
        padding: 20px;
        // background-color: #fdfaf7;
        background-color: #fff;
        color: #785e3a;
        border: solid 1px #c2b5a3;
        overflow: hidden;
    }
    
    .page .page-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    
    .page .page-content .page-header {
        height: 30px;
        font-size: 100%;
        text-transform: uppercase;
        text-align: center;
    }
    
    .page .page-content .page-image {
        height: 100%;
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    .page .page-content .page-text {
        height: 100%;
        flex-grow: 1;
        font-size: 80%;
        text-align: justify;
        margin-top: 10px;
        padding-top: 10px;
        box-sizing: border-box;
        border-top: solid 1px #f4e8d7;
    }
    
    .page .page-content .page-footer {
        height: 30px;
        border-top: solid 1px #f4e8d7;
        font-size: 80%;
        color: #998466;
        font-family: $Roboto;
        padding-top: 5px;
        text-align: center;
    }
    
    .page.page-none {
        display: none !important;
    }
    
    .page.--left {
        box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
    }
    
    .page.--right {
        box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
    }
    
    // .page.--right .page-footer {
    //     text-align: right;
    // }
    
    .page.hard {
        background-color: #f2e8d9;
        border: solid 1px #998466;
    }
    
    .page.page-cover {
        background-color: #e3d0b5;
        color: #785e3a;
        border: solid 1px #998466;
    }
    
    .page.page-cover h2 {
        text-align: center;
        padding-top: 50%;
        font-size: 210%;
    }
    
    .page.page-cover.page-cover-top {
        /* box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4); */
    }
    
    .page.page-cover.page-cover-top.--right {
        border: none;
        box-shadow: -3.5px 0 5px 0px rgba(0, 0, 0, 0.4);
        overflow: hidden;
    }
    
    .page.page-cover.page-cover-bottom {
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }

    @include setMinRwd{
        .page.--left {
            border-right: 0;
        }
        .page.--right {
            border-left: 0;
        }

        .page.page-cover.page-cover-bottom.--right {
            border-left: none;
        }
    }
    
    /* == Custome == */
    .stf__block {
        background-image: url('../images/flip-about-intro-img.png');
        background-position: left;
        background-repeat: repeat-x;
        background-size: 50%;
    }
    
    .stf__block::after {
        content: '感謝觀賞';
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 210%;
        padding-top: 25%;
        font-family: $fontNotosanc-tc;
    }
    
    .page-cover-bg,
    .page-cover-bottom {
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .page-cover-bg {
        background-image: url('../images/flip-Slide-index.jpeg');
    }
    
    .page-cover-bottom {
        box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4) !important;
        background-image: url('../images/flip-Slide-back.jpeg');
    }

    @include setMinRwd{
        .page.page-cover.page-cover-bottom.--left {
            border: 0;
            box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4) !important;
        }
    }
}

// 標題內容
.flip{
    &-header{}

    &-article{
        margin-top: 1em;
        margin-bottom: 2em;
    }
}
    
.container-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
}

.container-button .btn {
    border-radius: 0 !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none;
    outline: none !important;
}

.container-button .btn {
    width: 59px;
    height: 59px;
    background-image: url('../images/flip-arrows.png');
    text-indent: -9999px;
}

.container-button .btn-next {
    background: url('../images/flip-arrows.png') -59px 0;
}

.container-button-pagetxt {
    padding: 0 0.5em;
}

.curhide {
    display: none;
}

.curshow {
    display: block;
}
    
@include setMinRwd{
    .flip-container {
        position: relative;
        max-width: 70em;
    }

    .container-button {
        position: absolute;
        width: calc(100% + 7em);
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        justify-content: space-between;
    }
}
