// 產品項目說明
.pro-heading{
    &-des{
        border-bottom: 1px solid #676767;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    &-img{
        margin-bottom: 20px;
    }

    &-txt{
        color:#464545;
        @include fontSize(18px);
        padding: 10px 10px 15px;
    }
}

// 產品詳細頁
.Prod{
    &-conInfo{
        @include setMinRwd{
            display: flex;
            align-items: flex-start;
            margin-top: 3.5em;
        }

        &-img{
            overflow: hidden;
            @include setMinRwd{
                flex-basis: 630px;
            }

            @include setWidthRwd(1200px,1600px){
                flex-basis: 500px;
            }

            @include setWidthRwd(1100px,1199px){
                flex-basis: 400px;
            }

            @include setMaxRwd{
                padding-top: 0.5em;
            }

            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    // border: #ededed 1px solid;
                    // box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2) inset;
                    
                    background-color: #fff;
                    overflow: hidden;
                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        max-height: 100%;
                        margin: auto;
                    }
                }

                &-button-next,
                &-button-prev {
                    color: #000;
                    top: auto;
                    bottom: -95px;
                    &::after{
                        @include fontSize(20px);
                        @extend .fw-900;
                    }
                    
                    @include setWidthRwd(1200px,1600px){
                        bottom: -90px;
                    }

                    @include setWidthRwd(1100px,1199px){
                        bottom: get-vw(-140px);
                    }
                    
                    @include pad{
                        bottom: -6.5em;
                    }
                    
                    @include mobile{
                        bottom: -4.5em;
                    }
                }

                @include setMaxRwd{
                    &-button-next{
                        right: -1%;
                    }

                    &-button-prev{
                        left: -1%;
                    }
                }
            }
            
            // 大圖
            .Prod_mySwiper2 {
                height: 80%;
                width: 100%;
                @include radius(1.5em);
                margin-bottom: 0.25em;
                .swiper {
                    &-slide {
                        padding-bottom: 102%;
                    }
                }
                @include setMinRwd{
                    margin-bottom: 15px;
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px;
                .swiper{
                    &-slide {
                        padding-bottom: 24%;
                        background-color: transparent;
                        opacity: 0.4;
                        overflow: visible;

                        &-thumb-active {
                            opacity: 1;
                        }

                        @include setMinRwd{
                            &-thumb-active {
                                &::before{
                                    content: "";
                                    display: block;
                                    width: 114%;
                                    height: 107%;
                                    background-color: #fff;
                                    border: #909292 1px solid;
                                    position: absolute;
                                    top: -3.5%;
                                    left: -7%;
                                }
                            }
                        }

                        // @include setWidthRwd(1200px,1600px){
                        //     padding-bottom: 20%;
                        // }

                        // @include setWidthRwd(1100px,1199px){
                        //     padding-bottom: 25%;
                        // }

                        @include mobile{
                            padding-bottom: 30%;
                        }
                    }
                }
            }
        }

        &-info{
            padding-top: 0.5em;
            @include setMinRwd{
                flex: 1;
                padding-left: 3em;
            }
        }
    }

    &-heading{
        display: flex;
        line-height: 1.5;
        padding-top: 0.95em;
        padding-bottom: 0.45em;
        margin-bottom: 0;

        &:not(:last-child){
            border-bottom: #d3d3d3 1px solid;
        }

        &Title{
            flex-direction: column;
            color: #3c3c3c;
            border-bottom-width: 2px;
            border-bottom-color: #000;
            padding-left: 1em;

            &-num{
                 font-family: $Poppins, Arial;
                 --min-size: 20;
                 --max-size: 24;
                 --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                 font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                 @extend .fw-600
            }

            &-txt{
                --min-size: 18;
                --max-size: 20;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            }

            &-logo{
                position: absolute;
                right: 0;
            }
        }


        &Txt,
        &SubTitle{
            @include fontSize(16px);
            @extend .fw-400;
        }

        &Txt{
            position: relative;
            min-width: 150px;
            padding-left: 20px;
            color: #838484;
        }
        
        &SubTitle{
            a{
                &:hover{
                    color: $colorMain;
                }
            }

            .icon{
                margin-top: -1px; margin-right: 2px; vertical-align: top;
            }
        }
    }

    &-conTxt{
        margin-bottom: 1.5em;
    }

    &-btn-wrap{
        .btn {
            padding: 5px 20px;
            font-size: 0.9em;
        }
    }

    &-photo-small{
        padding-left: 1.5em;
        padding-right: 1.5em;
        @include setMinRwd{
            padding-left: 3em;
            padding-right: 3em;
            margin-top: 15px;
        }
    }

    &-unreset{
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        .nav-tabs{
            border-top: #bfbfbf 1px solid;
            border-bottom-color: #bfbfbf;
            @include setMinRwd{
                padding-left: 2em;
            }
        }

        .nav-item {
            min-height: auto;
            &::before{
                display: none !important;
            }
        }

        .nav-link{
            position: relative;
            @include fontSize(16px);
            @extend .fw-600;
            background-color: transparent !important;
            border: none;
            padding-right: 15px !important;
            padding-left: 15px !important;
            line-height: 2.5;

            &.active{
                color: $colorMain !important;
                &::after {
                    content: "";
                    display: block;
                    @include setSize(100%,3px);
                    background-color: $colorMain;
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .tab-content{
            border-bottom: #bfbfbf 1px solid;
            padding: 1em;
            @include setMinRwd{
                padding: 2.5em 2em;
            }
        }
    }

    &-conOther{
        &-card{
            .card{
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-left: 0;
                margin-right: 0;
                &-body {
                    padding-top: 0.75em;
                }
                &-img-top{
                    img {
                        width: 100%;
                    }
                }
            }
        }

        .swiper-button-wrap{
            display: flex;
            align-items: center;
            justify-content: space-around;
            max-width: 12.5em;
            margin: auto;
            .swiper-button-prev,
            .swiper-button-next{
                flex: none;
                position: static;
                @include setSize(55px,55px);
                background: #fff;
                color: #000;
                font-size: 1.25em;
                border-radius: 50%;
                border: #d2d2d2 1px solid;
                margin-top: 0;
                &::after{
                    display: none;
                }
            }
            .swiper-pagination{
                position: static;
                color: #bbbabb;
                font-family: $Poppins, Arial;
                &-current{
                    color: #000;
                }
            }
        }
    }
}

// 色碼挑選
.pixel {
    display: inline-block;
    @include setSize(25px,25px);
    border-radius: 50%;
    margin-right: 5px;
    @include setMaxRwd{
        margin-bottom: 5px !important;
    }
}