$procard-left: 1em;
$procard-right: 1em;

.pro{
    &-card-wrap{
        @include setMinRwd{
            margin-top: 2em;
        }


        // 列表
        .card{
            background-color: #fff;
            border:#fff 1px solid;
            padding: 15px;

            &, &-img-top{
                @include radius(0.5em);
                overflow: hidden;
            }

            @include setMinRwd{
                margin-left: $procard-left;
                margin-right: $procard-left;
                padding: 15px;

                &, &-img-top{
                    @include radius(1.25em);
                }

                &__3{
                    flex: 0 0 calc(100% / 3 - $procard-left*2);
                    max-width: calc(100% / 3 - $procard-left*2);
                }

                &:hover{
                    border-color: #e7e7e7
                }
            }
    
            &-img-top{
                height: 0;
                padding-bottom: 100%;
                img{ 
                    @extend %imgCenter;
                    transition: 0.25s;
                }

                @include setMinRwd{
                    img{
                        width: auto;
                    }
                }
            }

            &-body{
                padding-bottom: 0;
                @include setMinRwd{
                    padding-top: 1.1em;
                }

                @include setMaxRwd{
                    padding-left: 0.5em;
                    padding-right: 0.25em;
                }
            }

            &-head{
                font-family: $Poppins, Arial;
                --min-size: 18;
                --max-size: 20;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            }
    
            // 
            &-title{
                --min-size: 15;
                --max-size: 16;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                line-height: 1.05;
                margin-top: 0.35rem;
            }

            &-remark{
                position: absolute;
                right: 0;
                top: calc(50% + 0.35em);
                transform: translateY(-50%);
                margin-bottom: 0;
                @include setMaxRwd{
                    max-width: 2.5em;
                    transform: translateY(-125%);
                }
            }

            &-linkNormal{
                @include setMinRwd{
                    &:hover{

                        & ~ .card-img-top{
                            img{
                                transform: scale(1.25);
                            }
                        }

                        & ~ .card-body{
                            .card-btn{
                                background-color: $colorMain !important;
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                margin-bottom: 3em;
                &-img-top{
                    padding-bottom: 100%;
                }
            }
        }
    }
}