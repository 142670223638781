@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Raleway&display=swap");
.is-wrapper *, a, body {
  color: #231815;
  font-size: 16px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.65;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-form-flex, .contact-form-flex-list, .pageFlex, .page-gallerSwiper-pro .swiper-act-container, .index-pageWrapper, .card-wrap, .box, .pagination-wrap, .mainContent, .cookies-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.cooperate-card-wrap, .page-gallerSwiper-pro .swiper-act-button, .card-time, .btn-wrap, .radio_style, .content-header-wrap, .remark-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.inquery-form-card .card-img-top img, .download-card .card-img-top img, .pro-card-wrap .card-img-top img, .index-pageNewUpload-grid .card-img-top img, .imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1100px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-950 {
  width: 100%;
  max-width: 950px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650 {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.is-wrapper * {
  letter-spacing: 0px !important;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
@media (min-width: 1101px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
  .start-header.scroll-on .search-formList {
    top: 105px;
  }
}
.start-header .container {
  max-width: 100%;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (max-width: 1100px) {
  .navigation-wrap {
    padding: 5px 0;
    z-index: 1052;
  }
  .navigation-wrap.bg-light {
    background-color: transparent !important;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
}
@media (min-width: 1101px) {
  .navbar {
    padding-top: 15px;
    padding-left: 0.35em;
    padding-right: 0.35em;
  }
}
@media (min-width: 1600px) {
  .navbar {
    padding-left: 1.75em;
    padding-right: 1.75em;
  }
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (max-width: 1080px) {
  .navbar-brand {
    max-width: 31.25vw;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-collapse {
    flex: auto;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 45vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 1000;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1101px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none !important;
  position: fixed;
  left: 43%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block !important;
}
.navbar-nav.link {
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-nav.link .nav-item {
    text-align: center;
  }
  .navbar-nav.link .nav-item::after {
    content: "";
    display: block;
    width: 0;
    height: 4px;
    background-color: #231815;
    position: absolute;
    bottom: -0.5em;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease-out;
  }
  .navbar-nav.link .nav-item .nav-link {
    display: block;
    font-weight: 600;
    line-height: 2.5;
    padding: 0 10px !important;
    margin: 0 20px;
    transition: 0.25s;
  }
}
.navbar-nav.link .nav-item:hover::after, .navbar-nav.link .nav-item.active::after {
  width: 100%;
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  color: #000 !important;
  line-height: 1.05;
  letter-spacing: 0px;
}
@media (min-width: 1101px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-item {
    white-space: normal;
    padding: 10px 6px;
    text-align: center;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
    background-color: #231815 !important;
    color: white !important;
  }
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1100px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    font-size: 15px;
    font-size: 0.9375rem;
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.link {
    position: relative;
    justify-content: center;
    width: 100%;
  }
  .navbar-nav.link .nav-link {
    text-transform: uppercase;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    left: 50%;
    padding: 0 !important;
    transform: translate3d(-50%, 0px, 0);
    min-width: 12em;
    max-height: 100vw;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    padding: 10px;
    background: #f2f2f2;
    max-height: 100vw;
    border-radius: 5px;
    position: relative;
    top: 5px;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list::before {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .navbar-nav.link {
    justify-content: space-between;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    width: 100%;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.info {
    width: 100%;
    align-items: center;
    justify-content: center;
    order: -1;
  }
  .navbar-nav.info .nav-item-input {
    flex: 1;
  }
  .navbar-nav.info .nav-item-input .input-group {
    max-width: 25em;
    margin: auto;
  }
  .navbar-nav.info .nav-item-social {
    position: absolute;
    right: 0;
  }
  .navbar-nav.info .nav-item-social .nav-link {
    line-height: 1.5;
  }
}
@media (min-width: 1350px) and (max-width: 1600px) {
  .navbar-nav.info .nav-item-input .input-group {
    max-width: 20em;
  }
  .navbar-nav.info .nav-item-social .remark-list:not(:last-child) {
    margin-right: 1em;
    padding-right: 1em;
  }
}
@media (min-width: 1100px) and (max-width: 1349px) {
  .navbar-nav.info .nav-item-input .input-group {
    margin-left: 2em;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-search {
    display: none;
  }
  .navbar-nav.info .nav-item-input {
    display: none;
  }
  .navbar-nav.info .nav-item-social {
    padding: 10px;
  }
  .navbar-nav.info .nav-item-social::before {
    display: none;
  }
  .navbar-nav.info .nav-item-social .remark-list {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 1em;
  }
  .navbar-nav.info .nav-item-social .remark-list::after {
    display: none;
  }
  .navbar-nav.info .nav-item-social .remark-list.remark-lang .nav-link {
    display: none;
  }
  .navbar-nav.info .nav-item-social .remark-list.remark-lang .dropdown-menu {
    display: flex;
    opacity: 1;
    visibility: visible;
    max-height: 999px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .navbar-nav.info .nav-item-social .remark-list.remark-lang .dropdown-menu .dropdown-item {
    color: #fff;
    background-color: #231815;
    line-height: 2.5;
  }
  .navbar-nav.info .nav-item-social .remark-list.remark-social {
    display: inline-flex;
    justify-content: space-between;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #231815 !important;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1101px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #231815 !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-caret-down-fill {
  font-size: 12px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 1.5em;
}
.nav-item img {
  margin: 0 !important;
}
@media (min-width: 1701px) and (max-width: 1800px) {
  .nav-item {
    margin-left: 1.35em !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -1.3em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
}
.nav-item-input .input-group {
  border: 2px solid #171717;
  border-radius: 2em;
  overflow: hidden;
}
.nav-item-input .input-group .form-control {
  border: none;
  padding-left: 1.5rem;
}
.nav-item-input .input-group .btn {
  color: #000;
  line-height: 1.5;
  font-size: 1.1em;
  padding-left: 1em;
  padding-right: 1em;
}
.nav-item-input .input-group .btn:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
  border-color: transparent !important;
}
.nav-item-input .input-group .btn svg {
  margin-top: -5px;
}

.remark-item {
  display: block;
  border-radius: 10em;
  line-height: 2.25;
  border: #000000 1px solid;
  font-size: 1em;
  padding: 2px 20px !important;
  text-align: center;
}
.remark-item:hover {
  background-color: #231815;
  color: white;
}
.remark-wrapper .icon {
  margin-left: 1px;
  margin-right: 1px;
}
.remark-list:not(:last-child) {
  margin-right: 20px;
  padding-right: 20px;
}
.remark-list:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #bfbfbf;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.remark-social {
  padding-top: 3px;
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.5;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #231815;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 6.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1101px) {
  .nav-item .dropdown-menu {
    border-radius: 5px;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu .dropdown-menu-list li {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px;
  }
  .nav-item .dropdown-menu.show {
    padding: 0 !important;
  }
}

@media (max-width: 1100px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5em;
  }
  .nabar-icon-mobile .icon {
    margin-left: 5px;
  }
}

.toggle-search {
  position: relative;
  top: 2px;
  margin-right: 4px;
}
.toggle-search .icon-txt {
  --min-size: 18;
  --max-size: 34;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.toggle-search .icon-search {
  margin-top: -8px;
}

.search-formList {
  position: absolute;
  top: 100px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .search-formList {
    top: 5.25em;
  }
}
@media (max-width: 1100px) {
  .search-formList {
    top: 5em;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  background: #231815;
  border: none;
  cursor: pointer;
}

footer {
  padding: 1em;
  background-color: #f2f2f2;
}
footer .tag-item, footer .tag-item-current, footer .tag-item-date {
  font-size: 15px;
  background-color: #161616;
  color: #bfbfbf;
  padding: 5px 5px 6px;
}
@media (min-width: 1101px) {
  footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
footer .footer-info p {
  margin-bottom: 5px;
}
footer .footer-info .copyright {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #bfbfbf;
}
footer .footer-info-logo {
  padding-bottom: 0.65em;
}
footer .footer-info-logo ~ p {
  padding-left: 50px;
}
footer .footer-info-logo ~ p span.text-muted {
  position: absolute;
  left: 0;
  color: #adabab !important;
}
footer .footer-info-list .icon {
  padding-right: 10px;
  margin-top: -6px;
}
@media (min-width: 1101px) {
  footer .footer-info-list {
    padding-left: 50px;
  }
}
footer .footer-info-left, footer .footer-info-left-row, footer .footer-info-left-item {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
footer .footer-info-left-row {
  justify-content: space-around;
  padding-top: 70px;
}
@media (min-width: 1101px) {
  footer .footer-info-left-row {
    padding-left: 4em;
    padding-right: 4em;
  }
}
@media (max-width: 1100px) {
  footer .footer-info-left {
    flex-direction: column;
  }
  footer .footer-info-left-row {
    padding-top: 1.5em;
  }
  footer .footer-info-left-row li {
    flex: 0 0 50%;
    margin-bottom: 1em;
  }
  footer .footer-info-left-item {
    justify-content: center;
  }
  footer .footer-info-left-item .footer-info-list h5 {
    text-align: center;
  }
}
footer .footer-info-right {
  padding-top: 5px;
}
footer .footer-info-right .iware {
  margin-top: 0.5em;
}
@media (max-width: 1100px) {
  footer .footer-info-right {
    padding-top: 1.5em;
  }
  footer .footer-info-right p {
    font-size: 13px !important;
  }
}
footer .footer-link-head {
  font-size: 18px;
  margin-bottom: 25px;
}
footer .footer-link-head::before {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -12px;
}
@media (min-width: 1101px) {
  footer {
    padding: 45px 25px 30px;
  }
}
@media (max-width: 1100px) {
  footer {
    padding-bottom: 4em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  display: inline-block;
  background: #e5e5e5;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware, .iware a {
  color: #646262;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1101px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1100px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1100px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #231815;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    padding: 6px;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon {
    margin-left: 0;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
    padding-right: 5px;
  }
}

.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  text-align: center;
  line-height: 2;
}
@media (min-width: 1101px) {
  .cookies p {
    text-align: left;
    margin-bottom: 0;
  }
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 10px !important;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
}
.cookies-wrapper {
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1101px) {
  .cookies-wrapper {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
  }
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1101px) {
  .cookies .btn {
    font-size: 18px;
    font-size: 1.125rem;
    margin-left: 2.5em;
    padding: 0.5rem 2.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.in-content {
  padding-top: 2.6315789474vw;
  padding-bottom: 2.6315789474vw;
}
@media (max-width: 1100px) {
  .in-content {
    padding-top: 3.90625vw;
    padding-bottom: 3.90625vw;
  }
}

.unreset {
  font-size: 18px;
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}

.fw-400, .Prod-headingTxt, .Prod-headingSubTitle, .timeline__text {
  font-weight: 400 !important;
}
.fw-500, .remark-item {
  font-weight: 500 !important;
}
.fw-600, .Prod-unreset .nav-link, .Prod-headingTitle-num, .timeline__date, .index-pageCustomer-infoWrap .card-title-num, .index-pageArticle .card-head, .index-pageArticle .card-title, .searchResult-wrapper .searchResult-info-headTitle, .link-wrap ul > li a {
  font-weight: 600 !important;
}
.fw-900, .Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after, .page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after, .card-btn-more, .card-time-date, .pagination-wrap .pagination .page-link-arrow, .heading-title-article-slogan, .heading-title-txt, .heading-title-txt-en, .link-head, .nav-item-input .input-group .btn {
  font-weight: 900 !important;
}

.boxShadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1100px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1101px) {
  .mainContent-info {
    width: 210px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 210px);
    padding-left: 70px;
  }
}
@media (max-width: 1100px) {
  .mainContent-info {
    width: 100%;
  }
  .mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1100px) {
  .mainContent-list {
    padding: 0.5em 0.1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.link-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 20px;
}
.link-wrap ul > li {
  margin-bottom: 10px;
}
.link-wrap ul > li .icon {
  position: relative;
  width: 100%;
  height: 40px;
  max-width: 40px;
  max-height: auto;
  align-items: center;
  justify-content: center;
}
.link-wrap ul > li .icon img, .link-wrap ul > li .icon svg {
  position: absolute;
  transition: 0.15s;
}
.link-wrap ul > li .icon-default {
  left: 0;
}
.link-wrap ul > li .icon-cur {
  left: -1000%;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  --min-size: 17;
  --max-size: 19;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.5;
  color: #4e4f4f;
  padding: 0.5em 1em 0.25em 0.5em;
  border: solid 1px #cccccc;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  background-color: black;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active, .link-wrap ul > li > a.active::after {
  color: #fff;
}
.link-wrap ul > li > a:hover .icon-default, .link-wrap ul > li > a.active .icon-default {
  left: -1000%;
}
.link-wrap ul > li > a:hover .icon-cur, .link-wrap ul > li > a.active .icon-cur {
  left: 0;
}
.link-wrap ul > li > ol a {
  position: relative;
  display: block;
}
.link-wrap ul > li > ol li {
  background-color: #fff;
  border-bottom: #d3d3d3 1px dashed;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.link-wrap ul > li > ol li a {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #7c7b7b;
}
.link-wrap ul > li > ol li a::before {
  content: "\f231";
  font-family: bootstrap-icons !important;
  font-size: 0.65em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
  margin-right: 20px;
}
.link-wrap ul > li > ol li a:hover, .link-wrap ul > li > ol li a.active {
  color: #231815;
}
.link-wrap .first_menu.open {
  display: block;
}
@media (min-width: 1101px) {
  .link-wrap .first_menu {
    display: none;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 30px;
  font-size: 1.875rem;
  color: #231815;
  padding-left: 0.75rem;
  margin-bottom: 1.5rem;
}

.link-horizontal-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.link-horizontal-wrap .list-group {
  border: #e5e5e5 2px solid;
  flex-wrap: wrap;
  overflow: hidden;
}
.link-horizontal-wrap .list-group, .link-horizontal-wrap .list-group-item {
  border-radius: 2em;
}
.link-horizontal-wrap .list-group-item {
  position: relative;
  border: none;
}
.link-horizontal-wrap .list-group-item a {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #8c8c8d;
  display: block;
  text-align: center;
  padding: 0.55em 1em;
}
@media (min-width: 1101px) {
  .link-horizontal-wrap .list-group-item ~ li::before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #e5e5e5;
    position: absolute;
    left: -0.5em;
    top: 50%;
    margin-top: -2.5px;
    border-radius: 50%;
  }
}
.link-horizontal-wrap .list-group-item:only-child {
  margin-right: 0;
}
.link-horizontal-wrap .list-group-item.cur a {
  color: #fff;
  background-color: #231815;
  border-radius: 1.4em 1.5em 0em 1.4em !important;
}
.link-horizontal-wrap .list-group-item.cur:last-child a {
  border-radius: 1.4em 1.5em 1.4em 0em !important;
}
.link-horizontal-wrap .list-group-item.cur:only-child a {
  border-radius: 1.4em !important;
}
@media (min-width: 1101px) {
  .link-horizontal-wrap .list-group-item {
    flex: none;
    min-width: 10em;
    max-width: 100%;
    margin-right: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .link-horizontal-wrap .list-group-item:hover a {
    color: #fff;
    background-color: #231815;
    border-radius: 1.4em 1.5em 0em 1.4em !important;
  }
  .link-horizontal-wrap .list-group-item:hover:last-child a {
    border-radius: 1.4em 1.5em 1.4em 0em !important;
  }
  .link-horizontal-wrap .list-group-item:hover:only-child a {
    border-radius: 1.4em !important;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .link-horizontal-wrap .list-group-item {
    margin-right: 0.5em;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap .list-group-item.cur a {
    border-radius: 1.4em !important;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap .list-group {
    width: 100%;
    flex-wrap: wrap;
  }
  .link-horizontal-wrap .list-group-item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap {
    display: none;
  }
  .link-horizontal-wrap.open {
    display: flex;
  }
}

.btn.list-group-mobile-btn {
  color: #fff !important;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 3em !important;
}

.content-box {
  background-color: #f8f8f8;
}

.content-header {
  background-color: #fff;
  border-bottom: #e5e5e5 1px solid;
}
.content-header-wrap {
  justify-content: space-between;
}
@media (max-width: 1400px) {
  .content-header-wrap {
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .content-header-wrap {
    padding-left: 1em;
  }
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1100px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.titleLine_db {
  position: relative;
  border-bottom: 2px solid #e5e5e5;
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}
.titleLine_db::after {
  content: "";
  width: 100px;
  height: 2px;
  background-color: #231815;
  position: absolute;
  left: 0.5em;
  bottom: -2px;
  z-index: 2;
}
.titleLine_db_center {
  text-align: center;
  padding-left: 0;
}
.titleLine_db_center::after {
  left: 50%;
  transform: translateX(-50%);
}

.titleLine_left {
  position: relative;
  border-bottom: #e5e5e5 1px solid;
  padding: 0.45em 0 0.45em 0.85em;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.titleLine_left:before {
  content: "";
  width: 5px;
  height: 20px;
  background-color: #231815;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heading-title {
  text-align: left;
  margin-bottom: 1em;
}
.heading-title-txt {
  --min-size: 25;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  text-transform: uppercase;
}
.heading-title-txt, .heading-title-txt-en {
  position: relative;
  padding-left: 35px;
  padding-right: 0.5rem;
}
.heading-title-txt-en {
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  font-family: Helvetica;
}
@media (max-width: 1100px) {
  .heading-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.1em;
  }
  .heading-title-txt {
    margin-bottom: 0;
    padding-left: 0.5em;
  }
}
.heading-title::before {
  content: "";
  display: block;
  width: 248px;
  height: 5px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-image: url("../images/heading-title-icon.jpg");
}
@media (min-width: 1101px) {
  .heading-title::before {
    position: absolute;
    top: 15px;
    left: -248px;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .heading-title::before {
    left: -224px;
  }
}
@media (max-width: 1100px) {
  .heading-title::before {
    max-width: 3em;
  }
}
.heading-title-vertical .heading-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.heading-title-vertical .heading-title::before {
  position: static;
  max-width: 200px;
  order: 1;
}
@media (max-width: 1100px) {
  .heading-title-vertical .heading-title::before {
    max-width: 4em;
  }
}
.heading-title-vertical .heading-title-txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #4e4f4f;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 1em;
}
@media (max-width: 1100px) {
  .heading-title-vertical .heading-title-txt {
    margin-bottom: 1em;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.heading-title-article {
  border: #231815 1px solid;
  border-top: none;
  padding: 0 2em 1em;
}
.heading-title-article::before, .heading-title-article::after {
  content: "";
  display: block;
  width: 1.3em;
  height: 1px;
  background-color: #231815;
  position: absolute;
  top: 0;
}
.heading-title-article::before {
  left: 0;
}
.heading-title-article::after {
  right: 0;
}
.heading-title-article-slogan {
  --min-size: 24;
  --max-size: 48;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #231815;
  position: relative;
  top: -0.65em;
  margin-bottom: -0.65em;
  line-height: 1.1;
  text-transform: uppercase;
}
.heading-title-article-txt {
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.3em;
  color: #644689;
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
  text-transform: uppercase;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding-left: 0.15em;
  padding-right: 0.5em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #231815;
}
@media (max-width: 1100px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: -6px;
  margin-right: 2px;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #6e6d6d;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border-radius: 0%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #231815;
  border-color: #231815;
}
.pagination-wrap .pagination .page-link-arrow {
  color: #000;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  padding-left: 20px;
}
.pagination-wrap .pagination-mobile {
  max-width: 120px;
}
@media (min-width: 1101px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1100px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main {
  color: #231815 !important;
}
.text-itemSub {
  color: #7d7d7d !important;
}
.text-underLine {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1101px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1100px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.detail-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail-label-other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}
.detail-blockquote {
  display: block;
  background: #fff;
  padding: 15px 20px 12px 25px;
  margin: 0 0 20px;
  position: relative;
  /*Font*/
  font-size: 16px;
  /*Borders - (Optional)*/
  border-left: 10px solid #231815;
  /*Box Shadow - (Optional)*/
  box-shadow: 2px 2px 15px #ccc;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #231815;
}

.policy-section p,
.policy-section li {
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
}
.policy-section .title {
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5em;
}
.policy-section .policy-sec {
  margin-bottom: 40px;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1101px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn:hover {
  background-color: #231815 !important;
  border-color: #231815 !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #231815 !important;
  border-color: #231815 !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
  border-radius: 0 !important;
  padding: 0.35em 1.5em 0.5em;
}
@media (max-width: 1100px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: 0px;
  margin-right: 3px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-main {
  background-color: #231815;
  color: #fff;
}
.btn-white {
  background-color: #fff;
}
.btn-outline-dark {
  border: 1px solid #212529 !important;
}
.btn-corp {
  position: relative;
  z-index: 0;
  border-radius: 4em !important;
  line-height: 1.75em !important;
}
.btn-corp::before {
  content: "";
  display: block;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border: #000 1px solid;
  border-radius: 4em;
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 2.5em;
  margin-right: 2.5em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1101px) {
  .card__2 {
    flex: 0 0 calc(50% - 5em);
    max-width: calc(50% - 5em);
  }
}
@media (min-width: 1101px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 5em);
    max-width: calc(33.3333333333% - 5em);
  }
}
@media (min-width: 1101px) {
  .card__4 {
    flex: 0 0 calc(25% - 5em);
    max-width: calc(25% - 5em);
  }
}
@media (max-width: 1100px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 5em) !important;
  max-width: calc(100% - 5em) !important;
}
@media (min-width: 1101px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1101px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #231815 !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1100px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}

.empty-row {
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 640px;
}
.empty-row .img-box {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.empty-row .img-box img {
  display: block;
  width: 100%;
}
.empty-row .content {
  margin-top: 20px;
  text-align: center;
}
.empty-row .content .title {
  color: #222;
  font-size: 32px;
  font-family: "Open Sans";
  line-height: 1.2;
  margin: 0;
}
.empty-row .content .des {
  color: #999;
  font-size: 17px;
  line-height: 1.6;
  font-family: "Open Sans";
  margin: 0;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .empty-row .content {
    margin-top: 30px;
  }
  .empty-row .content .title {
    font-size: 26px;
  }
  .empty-row .content .des {
    font-size: 16px;
    margin-top: 15px;
  }
}
.error-row {
  background: url("../images/error-background.svg") no-repeat;
  background-position: center top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.error-row .img-box {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.error-row .img-box img {
  display: block;
  width: 100%;
}
.error-row .img-box .error-code {
  color: #555;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-57%);
}
.error-row .content {
  margin-top: 50px;
  text-align: center;
}
.error-row .content .title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.error-row .content .des {
  margin-top: 25px;
}
.error-row .content .des p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}
.error-row .content .des p:not(:last-child) {
  margin-bottom: 5px;
}
.error-row .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}
.error-row .btn-row .link {
  border-radius: 30px;
  background: #666666;
  color: #fff;
  display: block;
  font-size: 24px;
  padding: 15px 13px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: inherit;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 240px;
  line-height: 1;
}
.error-row .btn-row .link:hover {
  background: #333;
}

@media (max-width: 1440px) {
  .error-row .img-box {
    max-width: 540px;
  }
  .error-row .img-box .error-code {
    font-size: 100px;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .error-row .img-box {
    max-width: 440px;
  }
  .error-row .img-box .error-code {
    font-size: 70px;
    top: 45%;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .error-row .img-box .error-code {
    font-size: 50px;
    top: 45%;
  }
  .error-row .content {
    margin-top: 30px;
  }
  .error-row .content .title {
    font-size: 26px;
  }
  .error-row .content .des {
    margin-top: 15px;
  }
  .error-row .content .des p {
    font-size: 16px;
  }
  .error-row .btn-row {
    margin-top: 30px;
  }
  .error-row .btn-row .link {
    font-size: 17px;
    max-width: 170px;
  }
}
.searchResult-wrapper {
  position: relative;
  background-color: #f8f8f8;
  padding: 1.5em 2em 1.5em 1.5em;
}
.searchResult-wrapper .searchResult-info-list {
  background-color: #fff;
  padding: 1.25em 1.2em;
}
.searchResult-wrapper .searchResult-info-list:hover {
  background-color: #eee;
}
.searchResult-wrapper .searchResult-info-list:not(:last-child) {
  margin-bottom: 1em;
}
.searchResult-wrapper .searchResult-info-head {
  line-height: 1.75;
}
@media (min-width: 1101px) {
  .searchResult-wrapper .searchResult-info-head {
    padding-left: 5px;
  }
}
.searchResult-wrapper .searchResult-info-headTitle {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #020202;
  margin-bottom: 0.35em;
}
.searchResult-wrapper .searchResult-info-headsubTitle {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5d5d5e;
  line-height: 1.1;
}
@media (max-width: 1100px) {
  .searchResult-wrapper {
    padding: 0.5em;
  }
}

:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: $OpenSans-300;
  --fancy-slider-title-line-height: 1;
  /* Card */
  --card-transition-duration: 500ms;
  --card-transition-delay: 250ms; /* delay background color */
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 1; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .index-content {
    background-size: auto;
    padding: 1.5em 0.5em;
  }
}
@media (max-width: 1100px) {
  .index-pageWrapper {
    display: block;
    width: 100%;
  }
}
.index-pageSwiper {
  width: 100%;
  overflow: hidden;
}
.index-pageSwiper .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.index-pageSwiper .swiper-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: -2em;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
  width: 47px;
  height: 47px;
  overflow: hidden;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev::after,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next::after {
  display: none;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next svg path {
  fill: #231815;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev.swiper-button-disabled svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next.swiper-button-disabled svg path {
  fill: #bfbfbf;
}
@media (min-width: 1101px) {
  .index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
    position: static;
  }
}
.index-pageArticle {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  line-height: 1.5;
}
.index-pageArticle .btn {
  border-color: #d9d9d9 !important;
  padding: 5px 20px;
}
@media (max-width: 1100px) {
  .index-pageArticle .btn-wrap {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
  }
}
.index-pageArticle .card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0;
  background-color: transparent;
}
.index-pageArticle .card p {
  margin-bottom: 0;
}
.index-pageArticle .card-head, .index-pageArticle .card-title {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.1;
}
.index-pageArticle .card-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.index-pageArticle .card-text {
  color: #3c3c3c;
  font-weight: initial;
}
.index-pageArticle .card-img-top img {
  display: inline-block;
  width: auto;
}
.index-pageNewUpload {
  background-image: url("../images/index-pageNewUpload-bg.jpg");
}
.index-pageNewUpload-grid {
  padding-top: 2em;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.index-pageNewUpload-grid .card {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.index-pageNewUpload-grid .card-img-top {
  height: 0;
}
.index-pageNewUpload-grid .card-img-top img {
  width: 100%;
}
.index-pageNewUpload-grid .card-body {
  display: flex;
  align-items: stretch;
  padding: 0;
}
.index-pageNewUpload-grid .card-head {
  background-color: #000;
  color: #fff;
  flex-basis: 140px;
  padding: 2.25em 1.5em 1em;
}
.index-pageNewUpload-grid .card-caption {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.75);
  padding-top: 1.5em;
  padding-bottom: 0.875em;
}
.index-pageNewUpload-grid .card-text {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.index-pageNewUpload-grid .card-title {
  font-size: 16px;
  font-weight: 100 !important;
}
@media (min-width: 1101px) {
  .index-pageNewUpload-grid .card-body {
    position: absolute;
    bottom: 0;
    min-width: 28.5em;
  }
}
.index-pageNewUpload-grid .pageGrid:first-child .card:first-child .card-head {
  background-color: #53b7f1;
}
.index-pageNewUpload-grid .pageGrid:first-child .card:first-child ~ .card .card-head {
  background-color: #a5d047;
}
.index-pageNewUpload-grid .pageGrid:first-child .card .card-img-top {
  padding-bottom: 61.5%;
}
.index-pageNewUpload-grid .pageGrid:last-child .card .card-img-top {
  padding-bottom: 100%;
}
.index-pageNewUpload-grid .pageGrid:last-child .card:nth-child(1) .card-head {
  background-color: #e88a74;
}
.index-pageNewUpload-grid .pageGrid:last-child .card:nth-child(2) .card-head {
  background-color: #facd89;
}
.index-pageNewUpload-grid .pageGrid:last-child .card:nth-child(3) .card-head {
  background-color: #8f82bc;
}
@media (min-width: 1101px) {
  .index-pageNewUpload {
    padding-top: 4em;
    padding-bottom: 4em;
  }
  .index-pageNewUpload .btn-wrap {
    margin-top: -5.5%;
  }
}
.index-pageRecommend {
  background-image: url("../images/index-pageRecommend-bg.jpg");
}
.index-pageRecommend .index-pageWrapper {
  display: block;
}
.index-pageRecommend .heading-title::before {
  mix-blend-mode: color-burn;
}
@media (min-width: 1101px) {
  .index-pageRecommend {
    padding-top: 3.6842105263vw;
    padding-bottom: 11.0526315789vw;
  }
  .index-pageRecommend .btn-wrap {
    margin-top: -5.5%;
    margin-bottom: 1.5%;
  }
}
@media (max-width: 1100px) {
  .index-pageRecommend {
    padding-left: 0;
    padding-right: 0;
  }
}
.index-pageRecommend-swiper {
  max-width: 100%;
}
.index-pageRecommend-swiper .card {
  transform-origin: center;
  padding: 1.5em;
}
.index-pageRecommend-swiper .card, .index-pageRecommend-swiper .card-img-top {
  border-radius: 1.25em;
  overflow: hidden;
}
.index-pageRecommend-swiper .card-body, .index-pageRecommend-swiper .card-caption {
  width: 100%;
}
.index-pageRecommend-swiper .card-body {
  padding-left: 0;
  padding-top: 0.75em;
  padding-bottom: 0;
  padding-right: 0;
}
.index-pageRecommend-swiper .card-caption {
  padding-bottom: 0;
}
.index-pageRecommend-swiper .card::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.index-pageRecommend-swiper .changing,
.index-pageRecommend-swiper .changed {
  user-select: none;
}
.index-pageRecommend-swiper .changed {
  transition: none;
}
.index-pageRecommend-swiper .changing {
  transition: transform 0.3s;
  pointer-events: none;
}
.index-pageRecommend-swiper .changing.swiper-slide-active,
.index-pageRecommend-swiper .changed.swiper-slide-active {
  transform: translateX(0);
}
@media (min-width: 1101px) {
  .index-pageRecommend-swiper {
    padding-top: 4em;
  }
  .index-pageRecommend-swiper .card {
    transition: transform 0.3s;
  }
  .index-pageRecommend-swiper .card-body {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  .index-pageRecommend-swiper .card-linkNormal {
    transition: opacity 0.25s ease-in-out;
  }
  .index-pageRecommend-swiper .swiper {
    overflow: visible;
  }
  .index-pageRecommend-swiper .changing,
.index-pageRecommend-swiper .changed {
    transform: translateX(-100px);
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card,
.index-pageRecommend-swiper .changed.swiper-slide-active .card {
    transform: scale(1.5);
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card-body,
.index-pageRecommend-swiper .changed.swiper-slide-active .card-body {
    position: relative;
    opacity: 1;
    visibility: visible;
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card-text,
.index-pageRecommend-swiper .changed.swiper-slide-active .card-text {
    font-size: 82%;
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card-title,
.index-pageRecommend-swiper .changed.swiper-slide-active .card-title {
    font-size: 105%;
  }
  .index-pageRecommend-swiper .swiper-slide-active ~ .changing,
.index-pageRecommend-swiper .swiper-slide-active ~ .changed {
    transform: translateX(75px);
  }
}
@media (min-width: 1500px) {
  .index-pageRecommend-swiper .card {
    padding: 2.5em;
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card,
.index-pageRecommend-swiper .changed.swiper-slide-active .card {
    transform: scale(1.2);
  }
}
@media (max-width: 1100px) {
  .index-pageRecommend-swiper {
    padding-top: 1em;
  }
  .index-pageRecommend-swiper .card {
    padding: 0.5em;
    opacity: 0.5;
  }
  .index-pageRecommend-swiper .card-caption {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .index-pageRecommend-swiper .changing.swiper-slide-active .card,
.index-pageRecommend-swiper .changed.swiper-slide-active .card {
    opacity: 1;
  }
}
.index-pageRecommend-swiper .swiper-button-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  display: flex;
  max-width: 25%;
  width: 100%;
  justify-content: space-between;
  transform: translate(-50%, -50%);
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .index-pageRecommend-swiper .swiper-button-wrapper {
    max-width: 30%;
  }
}
@media (max-width: 1100px) {
  .index-pageRecommend-swiper .swiper-button-wrapper {
    max-width: calc(50% + 45px);
  }
}
.index-pageRecommend-swiper .swiper-button-next, .index-pageRecommend-swiper .swiper-button-prev {
  position: static;
  width: 55px;
  height: 55px;
}
.index-pageRecommend-swiper .swiper-button-next::after, .index-pageRecommend-swiper .swiper-button-prev::after {
  display: none;
}
@media (max-width: 1100px) {
  .index-pageRecommend-swiper .swiper-button-next, .index-pageRecommend-swiper .swiper-button-next svg, .index-pageRecommend-swiper .swiper-button-next svg > image, .index-pageRecommend-swiper .swiper-button-prev, .index-pageRecommend-swiper .swiper-button-prev svg, .index-pageRecommend-swiper .swiper-button-prev svg > image {
    width: 45px;
    height: 45px;
  }
}
.index-pageCustomer {
  background-image: url("../images/index-pageCustomer-bg.jpg");
  background-size: 100%;
  background-color: #e5e5e5;
}
.index-pageCustomer .heading-title {
  text-align: center;
  justify-content: center;
  margin-bottom: 1em;
}
.index-pageCustomer .heading-title-txt {
  color: #fff;
}
.index-pageCustomer .heading-title::before {
  position: absolute;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.75em;
  max-width: 50px;
  max-height: 6px;
  background-image: url("../images/heading-title-icon02.jpg");
}
.index-pageCustomer-listNav {
  margin-top: 4.25em;
  margin-bottom: 3.25em;
}
.index-pageCustomer-listNav .list-group-item {
  border-radius: 0 !important;
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0;
  text-align: center;
  margin-right: -1px;
}
.index-pageCustomer-listNav .list-group-item a {
  display: block;
  padding: 0.6em 0.35em;
  border: #fff 1px solid;
  --min-size: 15;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.index-pageCustomer-listNav .list-group-item:hover, .index-pageCustomer-listNav .list-group-item.active {
  background-color: black;
}
.index-pageCustomer-listNav .list-group-item:first-child {
  margin-right: -1px;
}
.index-pageCustomer-listNav .list-group-item:last-child {
  margin-left: 0px;
}
@media (min-width: 1101px) {
  .index-pageCustomer-listNav .list-group-item a {
    padding: 0.65em 2.75em;
  }
}
@media (max-width: 1100px) {
  .index-pageCustomer-listNav .list-group-item {
    flex: 1;
  }
}
@media (max-width: 1100px) {
  .index-pageCustomer-listNav {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
.index-pageCustomer-infoSwiper .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background-color: #bfbfbf;
}
.index-pageCustomer-infoSwiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: black;
}
@media (min-width: 1101px) {
  .index-pageCustomer-infoSwiper .swiper-pagination {
    width: 50% !important;
    left: 50% !important;
    bottom: 12.5% !important;
  }
}
@media (max-width: 1100px) {
  .index-pageCustomer-infoSwiper {
    padding-bottom: 3em;
  }
}
.index-pageCustomer-infoWrap .card-img-top {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.index-pageCustomer-infoWrap .card-body {
  background-color: #f3f2f2;
  padding: 4.2105263158vw 4.7368421053vw;
}
.index-pageCustomer-infoWrap .card-remark {
  position: absolute;
  right: 0;
  top: -3px;
}
.index-pageCustomer-infoWrap .card-title {
  font-size: 16px;
  color: #3c3c3c;
  margin-top: 1em;
}
.index-pageCustomer-infoWrap .card-title-num {
  font-size: 18px;
  padding-right: 10px;
}
.index-pageCustomer-infoWrap .card-caption {
  border-top: #bfbfbf 1px solid;
  border-bottom: #bfbfbf 1px solid;
  line-height: 1.5;
  padding: 1.5em 0;
  margin-top: 2em;
}
@media (min-width: 1101px) {
  .index-pageCustomer-infoWrap .card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .index-pageCustomer-infoWrap .card-img-top, .index-pageCustomer-infoWrap .card-body {
    width: 50%;
  }
}
.index-pageCustomer-infoWrap .btn {
  border-color: transparent !important;
}
.index-pageCustomer-infoWrap .btn-wrap {
  margin-top: 1.5em;
}
@media (min-width: 1101px) {
  .index-pageCustomer {
    padding-top: 6.3157894737vw;
    padding-bottom: 4.2105263158vw;
  }
}
@media (max-width: 1100px) {
  .index-pageCustomer {
    background-size: auto;
  }
}

.titlebox {
  display: inline-block;
  padding-right: 3.6842105263vw;
  padding-top: 3.9473684211vw;
  border-right: #231815 1px solid;
}

/* swiper index banner */
.swiper-bannerIn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Arrows */
}
.swiper-bannerIn .swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-bannerIn .swiper-image {
  height: 100%;
}
.swiper-bannerIn .swiper-image-inner {
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
}
.swiper-bannerIn .swiper-slide {
  transition: all 1s linear;
  transition-delay: 0.5s;
  opacity: 0;
  overflow: hidden;
}
.swiper-bannerIn .swiper-slide.swiper-slide-next {
  opacity: 0;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active {
  opacity: 1;
  width: 100% !important;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active .swiper-image {
  overflow: hidden;
}
.swiper-bannerIn .swiper-image.swiper-image-active img {
  width: 100%;
  height: 730px;
  object-fit: cover;
}
@media (min-width: 1100px) and (max-width: 1650px) {
  .swiper-bannerIn .swiper-image.swiper-image-active img {
    height: 40em;
  }
}
.swiper-bannerIn .swiper-image-inner.swiper-image-active {
  transition: all 1s linear;
  transition-delay: 1s;
  background-blend-mode: multiply;
}
.swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
  width: 39px;
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
.swiper-bannerIn .swiper-button-prev::after,
.swiper-bannerIn .swiper-button-next::after {
  display: none;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
    width: 40px;
    opacity: 0.75;
  }
  .swiper-bannerIn .swiper-button-prev svg image,
.swiper-bannerIn .swiper-button-next svg image {
    height: 45px;
  }
}
.swiper-bannerIn .swiper-button-prev svg,
.swiper-bannerIn .swiper-button-next svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.swiper-bannerIn .swiper-button-prev {
  left: 0;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-prev {
    left: -2%;
  }
}
.swiper-bannerIn .swiper-button-next {
  right: 0;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-next {
    right: -2%;
  }
}
.swiper-bannerIn .swiper-pagination {
  width: 100%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-bannerIn .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #000;
  opacity: 1;
}
.swiper-bannerIn .swiper-pagination-bullet-active {
  opacity: 1;
  background: transparent;
  transform: scale(2);
  border: #000 2px solid;
}
@media (min-width: 1101px) {
  .swiper-bannerIn .swiper-pagination {
    flex-direction: column;
    display: flex;
    min-height: 8em;
    bottom: auto !important;
    top: 50%;
    left: 2em !important;
    justify-content: space-around;
  }
}
.swiper-bannerIn .swiper-sideslogan {
  position: absolute;
  right: 4em;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-sideslogan {
    display: none;
  }
}
.swiper-bannerIn .swiper-sideslogan-text {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "Raleway", sans-serif;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 22em;
  width: 100%;
  transform: rotate(90deg) translateX(50%);
  transform-origin: right center;
}
.swiper-bannerIn .swiper-sideslogan-text::before {
  content: "";
  display: block;
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-right: 2em;
  overflow: hidden;
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
.page-banner.index {
  background: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 60%, #ffffff 60%, #f7f7f7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@media (max-width: 1100px) {
  .page-banner.index {
    height: 46.875vw;
  }
}
@media (max-width: 640px) {
  .page-banner-main img {
    height: 100px;
    object-fit: cover;
  }
  .page-banner-main::before {
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.page-gallerSwiper {
  overflow: hidden;
}
.page-gallerSwiper .swiper-pagination {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.page-gallerSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 0;
  margin: 0 !important;
}
.page-gallerSwiper .swiper-pagination-bullet-active {
  background-color: #231815;
}
.page-gallerSwiper .card-body {
  padding: 0;
}
.page-gallerSwiper .card-title {
  font-weight: 400;
  line-height: 1.15;
  word-wrap: normal;
}
.page-gallerSwiper .card-img-top {
  text-align: center;
}
.page-gallerSwiper .card-img-top img {
  display: inline-block;
}
.page-gallerSwiper .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper .swiper-pagination-bullet {
    opacity: 0.2;
  }
  .page-gallerSwiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper .swiper-pagination {
    justify-content: center;
  }
}
.page-gallerSwiper-pro {
  width: 100%;
  padding-top: 0.7894736842vw;
  padding-bottom: 2.1052631579vw;
}
.page-gallerSwiper-pro .card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-body {
    position: absolute;
    padding: 3em;
    color: #fff;
    background-color: rgba(49, 49, 49, 0.85);
  }
}
.page-gallerSwiper-pro .card-title {
  font-size: 42px;
  font-size: 2.625rem;
  font-family: var(--fancy-slider-title-font-family);
  border-bottom: #231815 1px solid;
  text-align: center;
  padding-bottom: 0.3em;
  margin-bottom: 0.35em;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-title {
    border-bottom-color: rgba(255, 255, 255, 0.65);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body {
    opacity: 0;
    transition: var(--card-transition-duration);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transition-duration: var(--card-transition-duration);
    transition-delay: var(--card-transition-delay);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title {
    transform: translateY(-1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transform: translateY(1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body {
    opacity: 1;
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-des {
    transform: translateY(0);
  }
}
@media (max-width: 810px) {
  .page-gallerSwiper-pro .card {
    flex-direction: row;
    align-items: flex-start;
    background-color: transparent;
  }
  .page-gallerSwiper-pro .card-img-top {
    border-radius: 20px;
    overflow: hidden;
    flex-basis: 150px;
    margin-top: 46px;
  }
  .page-gallerSwiper-pro .card-body {
    padding-left: 1.25em;
    padding-right: 0.25em;
    flex: 0 0 calc(100% - 150px);
  }
  .page-gallerSwiper-pro .card-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.page-gallerSwiper-pro .swiper-slide .card {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .swiper-slide-active .card, .page-gallerSwiper-pro .swiper-slide-next .card {
    border-bottom-right-radius: 0 !important;
  }
  .page-gallerSwiper-pro .swiper-slide-active .card {
    border-top-left-radius: 40%;
  }
  .page-gallerSwiper-pro .swiper-slide-next .card {
    border-radius: 20px;
  }
  .page-gallerSwiper-pro .swiper-slide .card {
    border-bottom-right-radius: 40%;
    overflow: hidden;
  }
}
.page-gallerSwiper-pro .swiper-act-container {
  align-items: flex-end;
  justify-content: end;
  margin-bottom: 2.75em;
  padding-right: 1em;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination {
  position: relative;
  width: auto;
  top: 0px;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  position: absolute;
  top: 25px;
  z-index: -1;
}
.page-gallerSwiper-pro .swiper-act-button {
  width: calc(3.5em + 10px);
  padding-left: 10px;
  justify-content: space-between;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev {
  position: static;
  color: #231815;
  height: auto;
  margin-top: 0;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after {
  font-size: 14px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction span {
  color: #b9b9b9;
  font-size: 15px;
  font-family: "Open Sans";
  padding: 0 2px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-empty {
  width: 30px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current {
  position: relative;
  color: #231815;
  font-weight: 600;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background-color: #231815;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1100px) {
  .page-gallerSwiper-pro {
    display: flex;
    flex-direction: column;
  }
  .page-gallerSwiper-pro .swiper-act-container {
    order: 1;
    margin-top: 1.1em;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-frAuto {
  grid-template-columns: repeat(2, auto) 1fr;
}
.pageGrid-list-1to1 {
  grid-template-columns: 1fr 1fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-3to1 {
  grid-template-columns: 1fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .pageGrid {
    grid-template-columns: auto !important;
  }
}
.about-content {
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1101px) {
  .about-content {
    margin-top: 2.6315789474vw;
    margin-bottom: 3.6842105263vw;
  }
}
.about-content-graybottom::before {
  content: "";
  display: block;
  width: calc(100% + 2rem);
  height: 100%;
  height: auto;
  min-height: 15.5em;
  background-color: #f0f0f0;
  position: absolute;
  left: -1em;
  bottom: -1.95em;
}
.about-content-graybottom.second::before {
  min-height: 24em;
}
.about-txtCont {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (min-width: 1101px) {
  .about-txtCont .unreset {
    padding-right: 30px;
  }
}
.about-txtCont.txtCont-bg {
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  background-image: url("../images/about-img03.png");
  padding: 10.5em 4em;
  margin-top: 50px;
  margin-bottom: 25px;
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 2;
}
@media (max-width: 1100px) {
  .about-txtCont.txtCont-bg {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1em;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
}

.timeline {
  display: flex;
  flex-direction: column;
}
.timeline__item {
  padding: 20px 20px 20px 50px;
  position: relative;
}
.timeline__item::before {
  background: #dbceeb;
  content: "";
  height: 100%;
  left: 16px;
  position: absolute;
  top: 0;
  width: 1px;
}
.timeline__item::after {
  box-shadow: inset 0px 0px 0px 5px #e4deea;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 20px;
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.timeline__item :last-child {
  margin-bottom: 0;
}
.timeline__date {
  color: #231815;
  --min-size: 20;
  --max-size: 21;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "Arimo", sans-serif;
  margin-bottom: 0.65em;
}
.timeline__text {
  color: #636364;
}
.timeline__item--checked::after {
  content: url("../images/timeline-icon-circle-small.png");
  text-align: center;
  background-color: #e4deea;
}
@media (min-width: 1101px) {
  .timeline.timeline--horizontal {
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 5rem;
  }
  .timeline.timeline--horizontal .timeline__item {
    padding: 70px 20px 20px 50px;
    flex: 1 1 0px;
  }
  .timeline.timeline--horizontal .timeline__item::before {
    height: 1px;
    top: 16px;
    width: 100%;
  }
  .timeline.timeline--horizontal .timeline__item::after {
    top: 0;
    left: 50px;
  }
}

@media (min-width: 1101px) {
  .pro-card-wrap {
    margin-top: 2em;
  }
}
.pro-card-wrap .card {
  background-color: #fff;
  border: #fff 1px solid;
  padding: 15px;
}
.pro-card-wrap .card, .pro-card-wrap .card-img-top {
  border-radius: 0.5em;
  overflow: hidden;
}
@media (min-width: 1101px) {
  .pro-card-wrap .card {
    margin-left: 1em;
    margin-right: 1em;
    padding: 15px;
  }
  .pro-card-wrap .card, .pro-card-wrap .card-img-top {
    border-radius: 1.25em;
  }
  .pro-card-wrap .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
  .pro-card-wrap .card:hover {
    border-color: #e7e7e7;
  }
}
.pro-card-wrap .card-img-top {
  height: 0;
  padding-bottom: 100%;
}
.pro-card-wrap .card-img-top img {
  transition: 0.25s;
}
@media (min-width: 1101px) {
  .pro-card-wrap .card-img-top img {
    width: auto;
  }
}
.pro-card-wrap .card-body {
  padding-bottom: 0;
}
@media (min-width: 1101px) {
  .pro-card-wrap .card-body {
    padding-top: 1.1em;
  }
}
@media (max-width: 1100px) {
  .pro-card-wrap .card-body {
    padding-left: 0.5em;
    padding-right: 0.25em;
  }
}
.pro-card-wrap .card-head {
  font-family: "Poppins", sans-serif, Arial;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.pro-card-wrap .card-title {
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.05;
  margin-top: 0.35rem;
}
.pro-card-wrap .card-remark {
  position: absolute;
  right: 0;
  top: calc(50% + 0.35em);
  transform: translateY(-50%);
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  .pro-card-wrap .card-remark {
    max-width: 2.5em;
    transform: translateY(-125%);
  }
}
@media (min-width: 1101px) {
  .pro-card-wrap .card-linkNormal:hover ~ .card-img-top img {
    transform: scale(1.25);
  }
  .pro-card-wrap .card-linkNormal:hover ~ .card-body .card-btn {
    background-color: #231815 !important;
  }
}
@media (min-width: 1101px) {
  .pro-card-wrap .card {
    margin-bottom: 3em;
  }
  .pro-card-wrap .card-img-top {
    padding-bottom: 100%;
  }
}

.pro-heading-des {
  border-bottom: 1px solid #676767;
  margin-top: 20px;
  margin-bottom: 45px;
}
.pro-heading-img {
  margin-bottom: 20px;
}
.pro-heading-txt {
  color: #464545;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 10px 15px;
}

@media (min-width: 1101px) {
  .Prod-conInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 3.5em;
  }
}
.Prod-conInfo-img {
  overflow: hidden;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img {
    flex-basis: 630px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .Prod-conInfo-img {
    flex-basis: 500px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .Prod-conInfo-img {
    flex-basis: 400px;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img {
    padding-top: 0.5em;
  }
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  background-color: #fff;
  overflow: hidden;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  margin: auto;
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  color: #000;
  top: auto;
  bottom: -95px;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -90px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -7.3684210526vw;
  }
}
@media (max-width: 810px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -6.5em;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -4.5em;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img .swiper-button-next {
    right: -1%;
  }
  .Prod-conInfo-img .swiper-button-prev {
    left: -1%;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  height: 80%;
  width: 100%;
  border-radius: 1.5em;
  margin-bottom: 0.25em;
}
.Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
  padding-bottom: 102%;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    margin-bottom: 15px;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  padding-bottom: 24%;
  background-color: transparent;
  opacity: 0.4;
  overflow: visible;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active::before {
    content: "";
    display: block;
    width: 114%;
    height: 107%;
    background-color: #fff;
    border: #909292 1px solid;
    position: absolute;
    top: -3.5%;
    left: -7%;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    padding-bottom: 30%;
  }
}
.Prod-conInfo-info {
  padding-top: 0.5em;
}
@media (min-width: 1101px) {
  .Prod-conInfo-info {
    flex: 1;
    padding-left: 3em;
  }
}
.Prod-heading {
  display: flex;
  line-height: 1.5;
  padding-top: 0.95em;
  padding-bottom: 0.45em;
  margin-bottom: 0;
}
.Prod-heading:not(:last-child) {
  border-bottom: #d3d3d3 1px solid;
}
.Prod-headingTitle {
  flex-direction: column;
  color: #3c3c3c;
  border-bottom-width: 2px;
  border-bottom-color: #000;
  padding-left: 1em;
}
.Prod-headingTitle-num {
  font-family: "Poppins", sans-serif, Arial;
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.Prod-headingTitle-txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.Prod-headingTitle-logo {
  position: absolute;
  right: 0;
}
.Prod-headingTxt, .Prod-headingSubTitle {
  font-size: 16px;
  font-size: 1rem;
}
.Prod-headingTxt {
  position: relative;
  min-width: 150px;
  padding-left: 20px;
  color: #838484;
}
.Prod-headingSubTitle a:hover {
  color: #231815;
}
.Prod-headingSubTitle .icon {
  margin-top: -1px;
  margin-right: 2px;
  vertical-align: top;
}
.Prod-conTxt {
  margin-bottom: 1.5em;
}
.Prod-btn-wrap .btn {
  padding: 5px 20px;
  font-size: 0.9em;
}
.Prod-photo-small {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media (min-width: 1101px) {
  .Prod-photo-small {
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 15px;
  }
}
.Prod-unreset {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Prod-unreset .nav-tabs {
  border-top: #bfbfbf 1px solid;
  border-bottom-color: #bfbfbf;
}
@media (min-width: 1101px) {
  .Prod-unreset .nav-tabs {
    padding-left: 2em;
  }
}
.Prod-unreset .nav-item {
  min-height: auto;
}
.Prod-unreset .nav-item::before {
  display: none !important;
}
.Prod-unreset .nav-link {
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  border: none;
  padding-right: 15px !important;
  padding-left: 15px !important;
  line-height: 2.5;
}
.Prod-unreset .nav-link.active {
  color: #231815 !important;
}
.Prod-unreset .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #231815;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}
.Prod-unreset .tab-content {
  border-bottom: #bfbfbf 1px solid;
  padding: 1em;
}
@media (min-width: 1101px) {
  .Prod-unreset .tab-content {
    padding: 2.5em 2em;
  }
}
.Prod-conOther-card .card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  margin-left: 0;
  margin-right: 0;
}
.Prod-conOther-card .card-body {
  padding-top: 0.75em;
}
.Prod-conOther-card .card-img-top img {
  width: 100%;
}
.Prod-conOther .swiper-button-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 12.5em;
  margin: auto;
}
.Prod-conOther .swiper-button-wrap .swiper-button-prev,
.Prod-conOther .swiper-button-wrap .swiper-button-next {
  flex: none;
  position: static;
  width: 55px;
  height: 55px;
  background: #fff;
  color: #000;
  font-size: 1.25em;
  border-radius: 50%;
  border: #d2d2d2 1px solid;
  margin-top: 0;
}
.Prod-conOther .swiper-button-wrap .swiper-button-prev::after,
.Prod-conOther .swiper-button-wrap .swiper-button-next::after {
  display: none;
}
.Prod-conOther .swiper-button-wrap .swiper-pagination {
  position: static;
  color: #bbbabb;
  font-family: "Poppins", sans-serif, Arial;
}
.Prod-conOther .swiper-button-wrap .swiper-pagination-current {
  color: #000;
}

.pixel {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}
@media (max-width: 1100px) {
  .pixel {
    margin-bottom: 5px !important;
  }
}

.download-card .card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 1101px) {
  .download-card .card {
    margin-left: 0.85em;
    margin-right: 0.85em;
  }
}
.download-card .card-img-top {
  border: #d9d9d9 3px solid;
  height: 0;
  padding-bottom: 100%;
}
@media (min-width: 1101px) {
  .download-card .card-img-top {
    padding-bottom: 125%;
  }
}
.download-card .btn-submain {
  background-color: #0091d4;
  border-color: #0091d4;
}
.download-card-new .card {
  background-color: transparent;
}
.download-card-new .card-img-top {
  border-color: #a2cbea;
  border-width: 10px;
}
@media (min-width: 1101px) {
  .download-card-new .card-img-top {
    width: 430px;
    padding-bottom: 65%;
  }
}
@media (min-width: 1101px) {
  .download-card-new .card-body {
    margin-left: 6.3157894737vw;
    margin-top: 4.2105263158vw;
  }
}
@media (min-width: 1101px) {
  .download-card-new .card-head {
    padding-bottom: 5.2631578947vw;
  }
}
@media (min-width: 1101px) {
  .download-card-new .btn {
    padding-left: 3.1578947368vw;
    padding-right: 3.1578947368vw;
  }
}
@media (max-width: 1100px) {
  .download-card-new .card-btn-wrap {
    display: flex;
  }
}

.flip-wrapper {
  margin: 2em auto;
}

.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  display: none;
  background-size: cover;
  /* == Custome == */
}
.flip-book .page {
  padding: 20px;
  background-color: #fff;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}
.flip-book .page .page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.flip-book .page .page-content .page-header {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}
.flip-book .page .page-content .page-image {
  height: 100%;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.flip-book .page .page-content .page-text {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}
.flip-book .page .page-content .page-footer {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
  font-family: "Roboto";
  padding-top: 5px;
  text-align: center;
}
.flip-book .page.page-none {
  display: none !important;
}
.flip-book .page.--left {
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.flip-book .page.--right {
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.flip-book .page.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}
.flip-book .page.page-cover {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
}
.flip-book .page.page-cover h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}
.flip-book .page.page-cover.page-cover-top {
  /* box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4); */
}
.flip-book .page.page-cover.page-cover-top.--right {
  border: none;
  box-shadow: -3.5px 0 5px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.flip-book .page.page-cover.page-cover-bottom {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
}
@media (min-width: 1101px) {
  .flip-book .page.--left {
    border-right: 0;
  }
  .flip-book .page.--right {
    border-left: 0;
  }
  .flip-book .page.page-cover.page-cover-bottom.--right {
    border-left: none;
  }
}
.flip-book .stf__block {
  background-image: url("../images/flip-about-intro-img.png");
  background-position: left;
  background-repeat: repeat-x;
  background-size: 50%;
}
.flip-book .stf__block::after {
  content: "感謝觀賞";
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 210%;
  padding-top: 25%;
  font-family: "Noto Sans TC";
}
.flip-book .page-cover-bg,
.flip-book .page-cover-bottom {
  background-repeat: no-repeat;
  background-size: cover;
}
.flip-book .page-cover-bg {
  background-image: url("../images/flip-Slide-index.jpeg");
}
.flip-book .page-cover-bottom {
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4) !important;
  background-image: url("../images/flip-Slide-back.jpeg");
}
@media (min-width: 1101px) {
  .flip-book .page.page-cover.page-cover-bottom.--left {
    border: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4) !important;
  }
}

.flip-article {
  margin-top: 1em;
  margin-bottom: 2em;
}

.container-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
}

.container-button .btn {
  border-radius: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  outline: none !important;
}

.container-button .btn {
  width: 59px;
  height: 59px;
  background-image: url("../images/flip-arrows.png");
  text-indent: -9999px;
}

.container-button .btn-next {
  background: url("../images/flip-arrows.png") -59px 0;
}

.container-button-pagetxt {
  padding: 0 0.5em;
}

.curhide {
  display: none;
}

.curshow {
  display: block;
}

@media (min-width: 1101px) {
  .flip-container {
    position: relative;
    max-width: 70em;
  }
  .container-button {
    position: absolute;
    width: calc(100% + 7em);
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    justify-content: space-between;
  }
}
@media (min-width: 1101px) {
  .cooperate-card-wrap {
    flex-wrap: nowrap;
  }
}

:root {
  --imgNum:100px;
}

.inquery-form-card {
  width: 100%;
}
.inquery-form-card-left {
  flex: 0 0 var(--imgNum);
  max-width: var(--imgNum);
  display: inline-flex;
  align-items: center;
}
.inquery-form-card, .inquery-form-card .card-img-top {
  border-radius: 0.75em;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .inquery-form-card, .inquery-form-card .card-img-top {
    border-radius: 0.5em;
  }
}
.inquery-form-card .card-img-top {
  width: var(--imgNum);
  height: 0;
  padding-bottom: 75%;
}
.inquery-form-card .card-body {
  padding: 0.75em 0.5em;
}
.inquery-form-card .txt-num {
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #a3cceb;
  color: #fff;
  border-radius: 50%;
  font-size: 0.875em;
  text-align: center;
  margin-right: 10px;
}
@media (max-width: 1100px) {
  .inquery-form-card {
    padding-right: 0;
  }
  .inquery-form-card-left {
    flex: 0 0 4.5em;
    max-width: 4.5em;
  }
  .inquery-form-card .card-img-top {
    width: 4.5em;
    margin-top: 0.2em;
    height: auto;
    padding-bottom: 0;
    border-radius: 2px;
  }
  .inquery-form-card .card-img-top img {
    position: static;
  }
  .inquery-form-card .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.inquery-form-list .table > :not(caption) > * > * {
  vertical-align: middle;
  border-width: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-width: 0 0 1px 0;
}
.inquery-form-list .table th {
  background: #f4f4f4;
}
.inquery-form-list .table th:first-child, .inquery-form-list .table th:last-child {
  width: 5em;
}
.inquery-form-list .table tbody tr {
  background-color: #fff;
}
.inquery-form-list .table tbody td {
  font-family: "Poppins", sans-serif, "Microsoft JhengHei", "微軟正黑體", "Arial";
}
.inquery-form-list .table .card-subinfo {
  display: flex !important;
  width: 100%;
  flex-wrap: wrap;
}
.inquery-form-list .table .card-subinfo p {
  margin-bottom: 0;
}
@media (min-width: 1101px) {
  .inquery-form-list .table .card-subinfo {
    align-items: center;
  }
}
@media (max-width: 1100px) {
  .inquery-form-list .table {
    margin-bottom: 0;
  }
  .inquery-form-list .table * {
    display: block;
  }
  .inquery-form-list .table > :not(caption) > * > * {
    padding-right: 0;
  }
  .inquery-form-list .table thead {
    display: none !important;
  }
  .inquery-form-list .table tbody tr {
    position: relative;
    margin-bottom: 1em;
  }
  .inquery-form-list .table tbody td {
    display: flex !important;
    padding: 0.2em;
  }
  .inquery-form-list .table tbody td:not(:last-child) {
    border-bottom-width: 0;
    padding: 0.5em 0.75em;
  }
  .inquery-form-list .table tbody td.table-td-num {
    display: none !important;
  }
  .inquery-form-list .table tbody td.table-td-cancel {
    position: absolute;
    right: 0em;
    top: 0.5em;
    border: none;
    justify-content: center;
  }
  .inquery-form-list .table tbody td.table-td-cancel a {
    display: inline-block;
    min-width: 40px;
  }
  .inquery-form-list .table .inquery-form-card .card-num,
.inquery-form-list .table .inquery-form-card .card-head {
    margin-top: 0.3em;
  }
  .inquery-form-list .table .inquery-form-card .card-num {
    line-height: 1;
  }
  .inquery-form-list .table .inquery-form-card .card-num *,
.inquery-form-list .table .inquery-form-card .card-head {
    font-size: 90% !important;
  }
  .inquery-form-list .table .inquery-form-card .card-subinfo {
    font-size: 0.75em;
    margin-top: 0.25em;
  }
  .inquery-form-list .table .inquery-form-card .card-subinfo p {
    margin-bottom: 0.1em;
  }
  .inquery-form-list .table .inquery-form-card .card-subinfo small {
    display: none;
  }
}

.txt-num {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}

.contact-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
  background-image: url("../images/contact_bgInfo.png");
}
@media (min-width: 1101px) {
  .contact-bg {
    padding-bottom: 120px;
  }
}
@media (min-width: 1251px) and (max-width: 1450px) {
  .contact-bg {
    padding-bottom: 140px;
  }
}
@media (max-width: 1080px) {
  .contact-bg {
    background-size: 100%;
    padding-bottom: 0;
  }
}
.contact-wrapper .btn-wrap {
  flex-wrap: wrap;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.contact-wrapper .btn-wrap .btn {
  font-size: 20px;
  font-size: 1.25rem;
  padding: 0.55rem 4.75rem;
}
@media (min-width: 1101px) {
  .contact-wrapper .btn-wrap {
    justify-content: space-between;
  }
  .contact-wrapper .btn-wrap li {
    flex: none;
    margin-left: 0 !important;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option {
    flex: 1;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option .btn-wrap {
    justify-content: flex-end;
  }
}
@media (max-width: 1100px) {
  .contact-wrapper .btn-wrap li {
    text-align: center;
    margin: 0 5px !important;
  }
  .contact-wrapper .btn-wrap .btn {
    width: 100%;
  }
}
@media (min-width: 1101px) {
  .contact-wrapper .radio_style {
    margin-top: 0 !important;
  }
}
@media (max-width: 1100px) {
  .contact-wrapper textarea {
    min-height: 10em;
  }
  .contact-wrapper .contact-map {
    order: -1;
  }
}
.contact-map {
  margin-top: 2em;
  margin-bottom: 2em;
  overflow: hidden;
}
.contact-form {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.contact-form-legend {
  padding: 0 1em 1.5em;
}
.contact-form-legend legend {
  float: none;
  font-size: 18px;
}
.contact-form .form-group {
  background-color: #fff;
  border: #dcd9d9 1px solid;
  padding: 10px 20px;
  margin-bottom: 20px;
}
@media (min-width: 1101px) {
  .contact-form .form-group {
    display: flex;
    align-items: start;
  }
  .contact-form .form-group > label {
    flex: none;
    min-width: 130px;
    border-right: #dcd9d9 1px solid;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 12px;
  }
}
.contact-form .form-control {
  border: none;
  border-radius: 0;
}
@media (max-width: 1100px) {
  .contact-form .form-control {
    background-color: #f5f5f5;
    margin-top: 0.35em;
  }
}
@media (min-width: 1101px) {
  .contact-form-flex, .contact-form-flex-list {
    align-items: center;
  }
}
.contact-btn-wrap {
  justify-content: space-between;
  padding-top: 0;
}