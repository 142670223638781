.empty-row{
    margin-left: auto;
    margin-right: auto;
    padding: 40px 20px;
    max-width: 640px;
    .img-box{
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
        img{
            display: block;
            width: 100%;
        }
    }
    .content{
        margin-top: 20px;
        text-align: center;
        .title{
            color: #222;
            font-size: 32px;
            font-family: $OpenSans-300;
            line-height: 1.2;
            margin: 0;
        }
        .des{
            color: #999;
            font-size: 17px;
            line-height: 1.6;
            font-family: $OpenSans-300;
            margin: 0;
            margin-top: 10px;
        }
    }
    // .btn-row{
    //     margin-top: 40px;
    //     .link{
    //         background: #aaa;
    //         color: #fff;
    //         display: block;
    //         font-size: 17px;
    //         margin-left: auto;
    //         margin-right: auto;
    //         padding: 13px;
    //         text-align: center;
    //         text-decoration: inherit;
    //         transition: all .4s;
    //         width: 120px;
    //         &:hover{
    //             background: #777;
    //         }
    //     }
    // }
}

@media (max-width:640px){
    .empty-row{
        .content{
            margin-top: 30px;
            .title{
                font-size: 26px;
            }
            .des{
                font-size: 16px;
                margin-top: 15px;
            }
        }
        // .btn-row{
        //     margin-top: 30px;
        // }
    }
}


// Empty status

// <!-- 404 -->
.error-row{
    background: url('../images/error-background.svg') no-repeat;
    background-position: center top;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    .img-box{
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;
        img{
            display: block;
            width: 100%;
        }
        .error-code{
            color: #555;
            font-size: 120px;
            font-weight: bold;
            letter-spacing: 5px;
            line-height: 1;
            margin: 0;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translateX(-57%);
        }
    }

    .content{
        margin-top: 50px;
        text-align: center;
        .title{
            color: #444;
            font-size: 30px;
            font-weight: bold;
            margin: 0;
        }
        .des{
            margin-top: 25px;
            p{
                color: #555;
                font-size: 18px;
                line-height: 1.6;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 5px;
                }
            }
        }
    }
    
    .btn-row{
        margin-top: 40px;
        margin-bottom: 20px;
        .link{
            border-radius: 30px;
            background: #666666;
            color: #fff;
            display: block;
            font-size: 24px;
            padding: 15px 13px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            text-decoration: inherit;
            text-transform: uppercase;
            transition: all .4s;
            width: 240px;
            line-height: 1;
            &:hover{
                background: #333;
            }
        }
    }
}

@media (max-width:1440px){
    .error-row {
        .img-box{
            max-width: 540px;
            .error-code{
                font-size: 100px;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:1024px){
    .error-row {
        .img-box{
            max-width: 440px;
            .error-code{
                font-size: 70px;
                top: 45%;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:640px){
    .error-row {
        .img-box{
            .error-code{
                font-size: 50px;
                top: 45%;
            }
        }
        .content{
            margin-top: 30px;
            .title{
                font-size: 26px;
            }
            .des{
                margin-top: 15px;
                p{
                    font-size: 16px;
                }
            }
        }
        .btn-row{
            margin-top: 30px;
            .link{
                font-size: 17px;
                max-width: 170px;
            }
        }
    }
}

// <!-- 搜尋結果 -->
.searchResult-wrapper{
    position: relative;
    background-color: #f8f8f8;
    padding: 1.5em 2em 1.5em 1.5em;

    .searchResult-info-list {
        background-color: #fff;
        padding: 1.25em 1.2em;
        &:hover{
            background-color: #eee;
        }
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    .searchResult-info-head {
        line-height: 1.75;
        @include setMinRwd{
            padding-left: 5px;
        }
    }

    .searchResult-info-headTitle {
        --min-size: 17;
        --max-size: 18;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        color: #020202;
        margin-bottom: 0.35em;
        @extend .fw-600
    }

    .searchResult-info-headsubTitle {
        --min-size: 16;
        --max-size: 16;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        color: #5d5d5e;
        line-height: 1.1;
    }

    @include setMaxRwd{
        padding: 0.5em;
    }
}