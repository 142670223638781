:root{
    --imgNum:100px;
}

.inquery-form{
    &-card{
        width: 100%;
        &-left{
            flex:0 0 var(--imgNum);
            max-width: var(--imgNum);
            display: inline-flex;
            align-items: center;
        }
        
        &, .card-img-top{
            @include radius(0.75em);
            overflow: hidden;
            @include setMaxRwd{
                @include radius(0.5em);
            }
        }
        
        .card-img-top{
            width: var(--imgNum);
            height: 0;
            padding-bottom: 75%;
            img{
                @extend %imgCenter;
            }
        }

        .card-body{
            padding: 0.75em 0.5em;
        }

        .txt-num{
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: #a3cceb;
            color: #fff;
            border-radius: 50%;
            font-size: 0.875em;
            text-align: center;
            margin-right: 10px;
        }

        @include setMaxRwd{
            padding-right: 0;

            &-left{
                flex: 0 0 4.5em;
                max-width: 4.5em;
            }

            .card-img-top{
                width: 4.5em;
                margin-top: 0.2em;
                height: auto;
                padding-bottom: 0;
                border-radius: 2px;
                img{
                    position: static;
                }
            }
            .card-body{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &-list{
        .table{
            & > :not(caption) > * > *{
                vertical-align: middle;
                border-width: 1px;
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                border-width: 0 0 1px 0;
            }

            th{
                background: #f4f4f4;

                &:first-child,
                &:last-child{
                    width: 5em;
                }
            }

            tbody{

                tr{
                    background-color: #fff;
                }

                td{
                    font-family: $Poppins, 'Microsoft JhengHei', '微軟正黑體', 'Arial';
                }
            }

            .card-subinfo{
                display: flex !important;
                width: 100%;
                flex-wrap: wrap;
                p{
                    margin-bottom: 0;
                }
            }

            @include setMinRwd{
                .card-subinfo{
                    align-items: center;
                }
            }

            @include setMaxRwd{
                margin-bottom: 0;

                *{
                    display: block;
                }

                & > :not(caption) > * > *{
                    padding-right: 0;
                }

                thead{
                    display: none !important;
                }

                tbody{
                    tr{
                        position: relative;
                        margin-bottom: 1em;
                    }
                    
                    td{
                        display: flex !important;
                        padding: 0.2em;
                        &:not(:last-child){
                            border-bottom-width: 0;
                            padding: 0.5em 0.75em;
                        }

                        &.table-td-num{
                            display: none !important;
                        }

                        &.table-td-cancel{
                            position: absolute;
                            right: 0em;
                            top: 0.5em;
                            border: none;
                            justify-content: center;
                            a{
                                display: inline-block;
                                min-width: 40px;
                            }
                        }

                        // &::before{
                        //     content: attr(data-title);
                        //     display: inline-flex;
                        //     align-items: center;
                        //     justify-content: center;
                        //     text-align: center;
                        //     flex: 0 0 80px;
                        //     font-size: 0.875em;
                        //     background-color: #f4f4f4;
                        //     margin-right: 10px;
                        // }
                    }
                }

                .inquery-form-card{
                    .card-num,
                    .card-head{
                        margin-top: 0.3em;
                    }

                    .card-num{
                        line-height: 1;
                    }

                    .card-num *,
                    .card-head{
                        font-size: 90% !important;
                    }

                    .card-subinfo{
                        font-size: 0.75em;
                        margin-top: 0.25em;
                        p{
                            margin-bottom: 0.1em;
                        }
                        small{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.txt-num{
    --min-size: 17;
    --max-size: 18;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}