// ----- 文字區塊 or 編輯器 ----- //
.article,
article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}

.in-content{
    padding-top: get-vw(50px);
    padding-bottom: get-vw(50px);

    @include setMaxRwd{
        padding-top: get-vw-mobile(25px);
        padding-bottom: get-vw-mobile(25px);
    }
}

.unreset{
    font-size: $fzSubtit;
}

.imgCenter-4-3{
    position: relative;
    @include setSize(100%,0);
    padding-bottom: 59.5%;
    overflow: hidden;
    img{
        @extend %imgCenter;
        max-height: none;
    }
}

// Google 驗證
.g-recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;

    @include setMaxRwd{
        margin-bottom: 1rem;
    }
}

// 文字粗體
.fw{
    &-400 {
        font-weight: 400 !important;
    }

    &-500 {
        font-weight: 500 !important;
    }

    &-600 {
        font-weight: 600 !important;
    }

    &-900 {
        font-weight: 900 !important;
    }
}

// 邊框陰影
.boxShadow{
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

// 邊框
.bt-solid-c1{
    border-top: 1px #ccc solid;
}

// => 內容
// 有左/右區塊使用
.mainContent{
    overflow: hidden;
    &-wrap{
        padding-bottom: 2em;
        background-color: #f8f8f8;
        @include setMaxRwd{
            padding-top: 1em;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @extend %setFlex;

    &-info{
        @include setMinRwd{
            width: 210px;

            &~.mainContent-list{
                max-width: calc(100% - 210px);
                padding-left: 70px;
            }
        }

        @include setMaxRwd{
            // display: none;
            width: 100%;
            .link-wrap{
                display: none;
            }
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;
        @include setMaxRwd{
            padding: 0.5em 0.1em;
        }
    }

    @include pad1024{
        flex-direction: column;
    }
}

// 內頁左側連結
.link{
    &-wrap {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding-bottom: 20px;
        ul {
            >li {
                margin-bottom: 10px;

                a{
                    @extend .fw-600;
                }

                .icon{
                    position: relative;
                    @include setSize(100%,40px);
                    @include setSizeMax(40px,auto);
                    align-items: center;
                    justify-content: center;
                    img, svg{
                        position: absolute;
                        transition: 0.15s;
                    }

                    &-default{
                        left: 0;
                    }

                    &-cur{
                        left: -1000%;
                    }
                }

                >a {
                    position: relative;
                    display: block;
                    --min-size: 17;
                    --max-size: 19;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    line-height: 1.5;
                    color: #4e4f4f;
                    padding: 0.5em 1em 0.25em 0.5em;
                    border: solid 1px #cccccc;
    
                    &:hover,
                    &.active {
                        &,
                        &::after{
                            color: #fff;
                        }

                        background-color: black;

                        .icon{
                            &-default{
                                left: -1000%;
                            }
                            &-cur{
                                left: 0;
                            }
                        }
                    }

                    &.active {
                    }
                }
    
                // 第二層
                &>ol{
                    a{
                        position: relative;
                        display: block;
                    }
    
                    li{
                        background-color: #fff;
                        border-bottom: #d3d3d3 1px dashed;
                        padding-left: 25px;
                        padding-top: 10px;
                        padding-bottom: 10px;

                        a{
                            --min-size: 16;
                            --max-size: 18;
                            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                            color: #7c7b7b;
                            &::before{
                                content: '\F231';
                                font-family: bootstrap-icons !important;
                                font-size: 0.65em;
                                display: inline-block;
                                vertical-align: middle;
                                margin-top: -6px;
                                margin-right: 20px;
                            }

                            &:hover,
                            &.active{
                                color: $colorMain;
                            }
                        }
                    }
                }
            }
        }

        // 第二層使用
        .first_menu{
            &.open{
                display: block;
            }
        }

        @include setMinRwd{
            // 第二層使用
            .first_menu{
                display: none;
            }
        }
    
        @include setWidthRwd(1020px,1500px) {
            a {
                @include fontSize(16px);
            }
        }
    
        @include pad1024{
            margin-bottom:1rem;
        }
    }

    &-head{
        @include fontSize(30px);
        color: $colorMain;
        @extend .fw-900;
        padding-left: 0.75rem;
        margin-bottom: 1.5rem;
    }
}

// 內頁水平連結
.link-horizontal{
    &-wrap{
        display: flex;
        justify-content: center;
        margin-bottom: 2em;
        .list-group{
            border: #e5e5e5 2px solid;
            flex-wrap: wrap;
            overflow: hidden;

            &,
            &-item{
                @include radius(2em);
            }

            &-item{
                position: relative;
                border: none;
                a{
                    --min-size: 16;
                    --max-size: 18;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    color: #8c8c8d;
                    display: block;
                    text-align: center;
                    padding:0.55em 1em;
                }

                & ~ li{
                    // margin-left: 1em;
                    @include setMinRwd{
                        &::before{
                            content: '';
                            display: block;
                            @include setSize(5px,5px);
                            background-color: #e5e5e5;
                            position: absolute;
                            left: -0.5em;
                            top: 50%;
                            margin-top: -2.5px;
                            @include radius(50%);
                        }
                    }
                }

                &:only-child{
                    margin-right: 0;
                }

                &.cur{
                    a{
                        color: #fff;
                        background-color: $colorMain;
                        border-radius: 1.4em 1.5em 0em 1.4em !important;
                    }

                    &:last-child{
                        a{
                            border-radius: 1.4em 1.5em 1.4em 0em !important;
                        }
                    }

                    &:only-child{
                        a{
                            border-radius: 1.4em !important;
                        }
                    }
                }

                @include setMinRwd{
                    flex: none;
                    min-width: 10em;
                    max-width: 100%;
                    margin-right: 1em;
                    padding-left: 0.5em;
                    padding-right: 0.5em;

                    &:hover{
                        a{
                            color: #fff;
                            background-color: $colorMain;
                            border-radius: 1.4em 1.5em 0em 1.4em !important;
                        }

                        &:last-child{
                            a{
                                border-radius: 1.4em 1.5em 1.4em 0em !important;
                            }
                        }

                        &:only-child{
                            a{
                                border-radius: 1.4em !important;
                            }
                        }
                    }
                }

                @include setWidthRwd(1100px,1500px){
                    margin-right: 0.5em;
                }

                @include setMaxRwd{
                    &.cur{
                        a{
                            border-radius: 1.4em !important;
                        }
                    }
                }
            }

            @include setMaxRwd{
                width: 100%;
                flex-wrap: wrap;
                &-item{
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-left: 0 !important;
                }
            }
        }

        @include setMaxRwd{
            display: none;
            &.open{
                display: flex;
            }
        }
    }
}

.btn{
    &.list-group-mobile-btn{
        color: #fff !important;
        display: inline-flex;
        justify-content: space-between;
        border-radius: 3em !important;
    }
}

// 內頁區塊
.content-box{
    background-color: #f8f8f8;
}

// 
.content-header{
    background-color: #fff;
    border-bottom: #e5e5e5 1px solid;
    &-wrap{
        @extend %setFlex-center;
        justify-content: space-between;

        @include setStyleRwd(1400px){
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding-left: 1em;
        }
    }
}

// -------- OTHER -------- //
.unit{
    position: relative;
    display: block;
    line-height: 1.5;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    .btn{
        padding-top: 0.6em;
        padding-bottom: 0.6em;
    }

    &-icon{
        padding-left: 1.5em !important;
        padding-right: 0;
        .icon{
            margin-top: 0;
            position: absolute;
            left: 0;
            top: -0.1rem;
        }
    }

    @include setMaxRwd{
        text-align: left;
        font-size: 16px;
        &-icon{
            .icon{
                top: 0.85rem;
            }
        }
    }
}

.select_style {
    &::after {
        content: '\F282';
        font-family:'bootstrap-icons' !important;
        display: block;
        @include fontSize(14px);
        position: absolute;
        right: 8px;
        top: 1px;
    }

    select.form-control {
        background-color: #fff;
        border: 1px solid #e6eaea !important;
        text-align: left;
        @include radius(0);
        height: 40px !important;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0; 
    }

    select {
        width: 100%;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
    }
    select,
    input {
        border: none !important;
        height: 100% !important;
    }
    .form-control {
        border-radius: 0;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

// -------- 標題樣式 -------- //
// 內容標題
.titleLine_db{
    position: relative;
    border-bottom: 2px solid #e5e5e5;
    --min-size: 20;
    --max-size: 24;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    padding-bottom: 0.5em;
    padding-left: 0.5em;

    &::after {
        content: '';
        @include setSize(100px,2px);
        background-color: $colorMain;
        position: absolute;
        left: 0.5em;
        bottom: -2px;
        z-index: 2;
    }

    &_center{
        text-align: center;
        padding-left: 0;
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.titleLine_left{
    position: relative;
    border-bottom: #e5e5e5 1px solid;
    padding: .45em 0 .45em 0.85em;
    --min-size: 18;
    --max-size: 20;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    &:before {
        content: '';
        @include setSize(5px,20px);
        background-color: $colorMain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

// 主標題
.heading-title{
    text-align: left;
    margin-bottom: 1em;
    &-txt{
        &,
        &-en{
            position: relative;
            @extend .fw-900;
            padding-left: 35px;
            padding-right: 0.5rem;
        }

        --min-size: 25;
        --max-size: 30;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        text-transform: uppercase;

        &-en{
            --min-size: 20;
            --max-size: 24;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            
            letter-spacing: 0;
            text-transform: uppercase;
            margin-bottom: 0.25em;
            font-family: Helvetica;
        }
    }

    @include setMaxRwd{
        display: flex;
        align-items: center;
        margin-bottom: 0.1em;
        &-txt{
            margin-bottom: 0;
            padding-left: 0.5em;
        }
    }

    &::before{
        content: '';
        display: block;
        @include setSize(248px,5px);
        // @include setSizeMax(248px,5px);
        @include setBackSet(left,cover);
        background-image: url('../images/heading-title-icon.jpg');
        @include setMinRwd{
            position: absolute;
            top: 15px;
            left: -248px;
        }

        @include setWidthRwd(1100px,1600px){
            left: -224px;
        }

        @include setMaxRwd{
            max-width: 3em;
        }
    }

    // => 內頁用
    // 垂直
    &-vertical{
        .heading-title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            &::before {
                position: static;
                max-width: 200px;
                order: 1;
                @include setMaxRwd{
                    max-width: 4em;
                }
            }

            &-txt {
                --min-size: 18;
                --max-size: 20;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                color: #4e4f4f;
                padding-left: 4rem;
                padding-right: 4rem;
                margin-top: 1em;
                @include setMaxRwd{
                    margin-bottom: 1em;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }

    // 主標題 : 文案用
    &-article{
        border:$colorMain 1px solid;
        border-top: none;
        padding: 0 2em 1em;

        &::before,
        &::after{
            content: '';
            display: block;
            @include setSize(1.3em, 1px);
            background-color: $colorMain;
            position: absolute;
            top: 0;
        }

        &::before{
            left: 0;
        }

        &::after{
            right: 0;
        }

        &-slogan{
            --min-size: 24;
            --max-size: 48;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            @extend .fw-900;
            color: $colorMain;
            position: relative;
            top: -0.65em;
            margin-bottom: -0.65em;
            line-height: 1.1;
            text-transform: uppercase;
        }
        
        &-txt{
            --min-size: 18;
            --max-size: 24;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            margin-bottom: 0.3em;
            color: #644689;
        }
    }
}

// 麵包屑
.breadcrum-wrap {
    // margin-top: get-vw(15px);
    // margin-bottom: get-vw(15px);

    // @include setStyleRwd(1600px){
    //     padding-right: get-vw-mobile(15px);
    // }

    // @include setMaxRwd{
    //     margin-top: get-vw-mobile(15px);
    //     margin-bottom: get-vw-mobile(15px);
    // }

    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: 0;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        .breadcrumb-item{
            color: #777;
            text-transform: uppercase;
            &+.breadcrumb-item{
                &::before {
                    content: ">";
                    padding-left: 0.15em;
                    padding-right: 0.5em;
                }
            }

            &.active{
                color: $colorMain;
            }
        }

        @include setMaxRwd{
            padding-left: 0;
            padding-top: 0.3em;
            padding-bottom: 0;
        }
    }

    .icon{
        margin-top: -6px;
        margin-right: 2px;
    }
}

// 頁碼
.pagination-wrap {
    @extend %setFlex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(50px,50px);
            line-height: 50px;
            color: #6e6d6d;
            font-family: $Poppins;
            background-color: #fff;
            @include radius(0%);
            text-align: center;
            padding: 0;
            margin-right: 0.5rem;
            &:hover,
            &.active {
                color: #fff;
                background-color:$colorMain;
                border-color:$colorMain;
            }

            &-arrow{
                color: #000;
                @extend .fw-900;
            }
        }
    }

    .pagination-select{
        display: flex;
        align-items: center;
        justify-content: center;
        select{
            text-align: center;  
            appearance: auto;
            @include fontSize(15px);
        }

        .pagination-item{
            padding-left: 20px;
        }
    }

    .pagination-mobile{
        max-width: 120px;
    }

    @include setMinRwd{
        .pagination-desktop{}

        .pagination-mobile{
            .pagination-select{
                width: auto;
                select{
                    max-width: 45px;
                }
            }
        }
    }

    @include setMaxRwd{
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}


//-------- 文字 --------//
// 顏色
$textColor-colors: (Main: $colorMain,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: $colorSubText);

.text{
    &-main {
        color: map-get($textColor-colors , Main) !important;
    }

    &-itemSub{
        color: map-get($textColor-colors , itemSub) !important;
    }

    // 樣式
    &-underLine{
        text-decoration: underline !important;
    }

    &-uppercase{
        text-transform: uppercase;
    }

    &-italic{
        font-style: italic;
    }
}

//-------- 區塊色框 --------//
// 顏色
$bgColor-colors: (Main: #064583,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: #6f6e6e);

.bg{
    &-itemSub{
        background-color: map-get($bgColor-colors , itemSub) !important;
    }
}


// 左右對齊
.justify{
    text-align: justify;
}

//-------- 自訂 --------//
.flex{
    &-none{
        flex: none !important;

        @include mobile{
            width: 100%;
        }
    }

    &-auto{
        flex: auto !important
    }

    &-1{
        flex: 1 !important
    }
}


// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    font-size: 14px;
    line-height: 1;
    padding: 6px;
    text-align: center;
    background-color: $bgSecondary;
    color: $colorText; 

    &-current,
    &-date{
        @extend .tag-item;
        @include fontSize(18px);
        color: #fff;
        padding: 8px 16px;
        @include radius(6px);
    }

    &-current{
        background-color: #00b7ee;
    }

    &-date{
        background-color: #bfbf85;
    }

    &-wrap{
        .tag-item{
            & ~ .tag-item{
                margin-left: 5px;
            }
        }

        @include setMinRwd{
            padding-left: 15px;
        }

        @include setMaxRwd{
            padding-top: 10px;
        }
    }
}

.detail{
    &-label {
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px;
        padding: 0 6px;
        height: 20px;
        text-align: center;
        background-color: #fff;
        line-height: 20px;
        vertical-align: middle;
        font: 13px/25px Arial;
        border: 1px solid #fff;
        &-other {
            border-color: #e9a3a8;
            color: #e9a3a8;
            line-height: 20px;
        }
    }

    &-blockquote{
        display:block;
        background: #fff;
        padding: 15px 20px 12px 25px;
        margin: 0 0 20px;
        position: relative;
        
        /*Font*/
        font-size: 16px;
        
        /*Borders - (Optional)*/
        border-left: 10px solid $colorMain;
        // border-right: 2px solid #c76c0c;
        
        /*Box Shadow - (Optional)*/
        box-shadow: 2px 2px 15px #ccc;
    }
}

.radio{
    &_style {
        @extend %setFlex-center;
        margin-top: 10px !important;
        input {
            &[type="radio"] {
                display: none;
            }
            &+label {
                position: relative;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-left: 30px;
                margin-bottom: 0;
                &::before,
                &::after {
                    content: "";
                    display: inline-block;
                    border-radius: 50%;
                }
                &::before {
                    width: 20px;
                    height: 20px;
                    border: #cecece 1px solid;
                    background: #fff;

                    position: absolute;
                    top: -1px;
                    left: 0;
                }
                &::after {
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    position: absolute;
                    left: 5px;
                    top: 4px;
                }
            }
            &:checked {
                &+label {
                    &::after {
                        background-color: $colorMain;
                    }
                }
            }
        }
    }
}

// 
.policy-section{
    p,
    li {
      color: #333;
      font-size: 1rem;
      line-height: 1.5;
    }

    .title {
        font-weight: bold;
        font-size: x-large;
        margin-bottom: 0.5em;
    }

    .policy-sec {
        margin-bottom: 40px;
    }
}